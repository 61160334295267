const ColorDiv = (props) => {
  return (
    <div className="col-2 mx-1">
      <span style={{ color: "grey", fontSize: "14px" }}>{props.bgcolor}</span>
      <span
        style={{
          display: "inline-block",
          width: "100%",
          height: "100px",
          background: props.bgcolor,
        }}
      ></span>
    </div>
  );
};
export default ColorDiv;
