import ColorDiv from "../custom/Palettes/ColorDiv";
import {
  analogous,
  get_analogous_pointer_degree,
  analogous_other_pointer,
} from "../custom/HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import {
  setWheelPointers,
  setAnalogousDegree,
} from "../redux/AllSlices/MainSlice";
import FetchFabricBtn from "./FetchFabricBtn";
import FabricListDiv from "./FabricListDiv";

const fabricKey = "analogous_fabric";
const Analogous = (props) => {
  const [colorArr, setColorArr] = useState([]);

  useEffect(() => {
    const colors = analogous(props.baseColor);
    setColorArr(colors);
  }, [props.baseColor]);

  //   useEffect(() => {
  //     if (props.otherPointer != false) {
  //       const new_degree = get_analogous_pointer_degree(props.otherPointer);
  //       props.dispatch(setAnalogousDegree(new_degree));
  //     }
  //   }, [props.otherPointer]);

  //   useEffect(() => {
  //     if (props.otherPointer != false) {
  //       const colors = analogous_other_pointer(props.otherPointer);
  //       setColorArr(colors);
  //       props.dispatch(setWheelPointers(colors));
  //     }
  //   }, [props.analogousDegree]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <div className="d-flex">
      <div className="col-6">
        <div className="mb-2 text-center fw-bold">Analogous harmony (3) </div>
        <div className="d-flex">{content}</div>
      </div>
      <div className="col-6">
        <div className="text-center">
          <FetchFabricBtn colors={colorArr} statekey={fabricKey} />
        </div>
        {props.fabricData.message.fabrics && (
          <FabricListDiv fabricList={props.fabricData.message.fabrics} />
        )}
      </div>
    </div>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    fabricData: { ...state.Forms[fabricKey] },
  };
})(Analogous);
