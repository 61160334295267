import { connect } from "react-redux";
import { getMatchingFabric } from "../redux/AllThunk/DbColos";

const FetchFabricBtn = (props) => {
  const get_machingFabric = () => {
    props.dispatch(
      getMatchingFabric({
        payload: {
          colors: props.colors,
        },
        key: props.statekey,
      })
    );
  };

  return (
    <>
      <button
        onClick={() => {
          get_machingFabric();
        }}
      >
        Get Fabrics
      </button>
    </>
  );
};
export default connect()(FetchFabricBtn);
