import { useEffect, useState } from "react";
import { Row, Container } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";

import { MethodList } from "./MethodList";
//import IroWheel from "../IroWheel";
import IroWheel from "../iroWheelnew";
import {
  setBaseColor,
  setOtherPointerMoved,
  setActiveHarmony,
} from "../redux/AllSlices/MainSlice";
import Complementary from "./Palettes/Complementary";
import Analogous from "./Palettes/Analogous";
import Monochromatic from "./Palettes/Monochromatic";
import Triad from "./Palettes/Triad";
import SplitCompelmentary from "./Palettes/SplitCompelmentary";
import DoubleSplitComplementary from "./Palettes/DoubleSplitComplementary";
import Square from "./Palettes/Square";
import Shades from "./Palettes/Shades";
import Compound from "./Palettes/Compound";
import CustomPalette from "./Palettes/CustomPalette";
import { TinyColor } from "@ctrl/tinycolor";

import { parseColor } from "@react-stately/color";

const Custom = (props) => {
  const formik = useFormik({
    initialValues: {
      colorharmony: "Triad",
      colorInput: props.baseColor,
    },
  });

  const OtherPointerChangeHandler = (value, index) => {
    props.dispatch(
      setOtherPointerMoved({ color: value, pointer_index: index })
    );
  };

  const BaseColorChangeHander = (value) => {
    const a = parseColor(value);
    props.dispatch(setBaseColor(value));
    //console.log("testing++++++++++++++++++");
    props.dispatch(setOtherPointerMoved(false));
  };

  useEffect(() => {
    const tiny = new TinyColor(formik.values.colorInput);
    const a = parseColor(tiny.toString("rgba"));
    props.dispatch(setBaseColor(a.toString("rgba")));
  }, [formik.values.colorInput]);

  useEffect(() => {
    props.dispatch(setOtherPointerMoved(false));
    props.dispatch(setActiveHarmony(formik.values.colorharmony));
  }, [formik.values.colorharmony]);
  return (
    <Container fluid>
      <FormikProvider value={formik}>
        <div className="text-center fw-bold mb-3">Custom Palette Generator</div>
        <div
          className="text-center mb-3"
          style={{ backgroundColor: props.baseColor }}
        >
          Base Color :<span className="fw-bold">{props.baseColor}</span>
        </div>
        <Row>
          <div className="col-6">
            <Form.Label>Choose Color Harmony Rule</Form.Label>
            <MethodList />
          </div>
          <div className="col-6">
            <IroWheel
              BaseColorChangeHander={BaseColorChangeHander}
              OtherPointerChangeHandler={OtherPointerChangeHandler}
            />
          </div>
        </Row>
        {formik.values.colorharmony == "Analogous" && <Analogous />}
        {formik.values.colorharmony == "Complementary" && <Complementary />}
        {formik.values.colorharmony == "Monochromatic" && <Monochromatic />}
        {formik.values.colorharmony == "Triad" && <Triad />}
        {formik.values.colorharmony == "Split Complementary" && (
          <SplitCompelmentary />
        )}
        {formik.values.colorharmony == "Double Split Complementary" && (
          <DoubleSplitComplementary />
        )}
        {formik.values.colorharmony == "Square" && <Square />}
        {formik.values.colorharmony == "Shades" && <Shades />}
        {formik.values.colorharmony == "Compound" && <Compound />}
        {formik.values.colorharmony == "Custom" && <CustomPalette />}
      </FormikProvider>
    </Container>
  );
};
export default connect((state) => {
  return { baseColor: state.mainslice.baseColor };
})(Custom);
