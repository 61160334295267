import ColorDiv from "../custom/Palettes/ColorDiv";
import {
  double_split_complement,
  get_doubleSplitcomplementary_pointer_degree,
  double_split_complement_otherpointer,
} from "../custom/HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import {
  setWheelPointers,
  setDoubleSplitComplementDegree,
} from "../redux/AllSlices/MainSlice";
import FetchFabricBtn from "./FetchFabricBtn";
import FabricListDiv from "./FabricListDiv";

const fabricKey = "doubleSplitCompliment_fabric";

const DoubleSplitcomplementary = (props) => {
  const [colorArr, setColorArr] = useState([]);
  useEffect(() => {
    const colors = double_split_complement(props.baseColor);
    setColorArr(colors);
    props.dispatch(setWheelPointers(colors));
  }, [props.baseColor]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <div className="d-flex">
      <div className="col-6">
        <div className="mb-2 text-center fw-bold">
          Double Split Compelmentary harmony (4)
        </div>
        <div className="d-flex">{content}</div>
      </div>
      <div className="col-6">
        <div className="text-center">
          <FetchFabricBtn colors={colorArr} statekey={fabricKey} />
        </div>
        {props.fabricData.message.fabrics && (
          <FabricListDiv fabricList={props.fabricData.message.fabrics} />
        )}
      </div>
    </div>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    fabricData: { ...state.Forms[fabricKey] },

    // otherPointer: state.mainslice.otherPointer,
    // Degree: state.mainslice.doubleSplitComplementDegree,
  };
})(DoubleSplitcomplementary);
