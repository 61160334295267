import { configureStore } from "@reduxjs/toolkit";
import { Middleware } from "@reduxjs/toolkit";
import MainSlice from "./AllSlices/MainSlice";
import Forms from "./AllReducers/Forms";
import thunk from "redux-thunk";
import GetData from "./AllReducers/GetData";
export default configureStore({
  reducer: {
    mainslice: MainSlice,
    Forms: Forms,
    GetData: GetData,
  },
  middleware: [thunk],
  // middleware:(getDefaultMiddleware)=>{
  //   getDefaultMiddleware().concat()
  // }
});
