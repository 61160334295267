import ColorDiv from "./ColorDiv";
import { analogous, custom_palette } from "../HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row } from "react-bootstrap";
import { setWheelPointers } from "../../redux/AllSlices/MainSlice";

const CustomPalette = (props) => {
  const [colorArr, setColorArr] = useState([]);
  useEffect(() => {
    const colors = custom_palette(props.baseColor);
    setColorArr(colors);
  }, [props.baseColor]);

  useEffect(() => {
    if (props.otherPointer != false) {
      const colors = custom_palette(props.otherPointer);
      setColorArr(colors);
      props.dispatch(setWheelPointers(colors));
    }
  }, [props.otherPointer]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <>
      <Row>Custom harmony </Row>
      <div className="d-flex">{content}</div>
    </>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    otherPointer: state.mainslice.otherPointer,
  };
})(CustomPalette);
