import ColorDiv from "./ColorDiv";
import {
  double_split_complement,
  get_doubleSplitcomplementary_pointer_degree,
  double_split_complement_otherpointer,
} from "../HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import {
  setWheelPointers,
  setDoubleSplitComplementDegree,
} from "../../redux/AllSlices/MainSlice";

const DoubleSplitcomplementary = (props) => {
  const [colorArr, setColorArr] = useState([]);
  useEffect(() => {
    const colors = double_split_complement(props.baseColor);
    setColorArr(colors);
    props.dispatch(setWheelPointers(colors));
  }, [props.baseColor]);

  useEffect(() => {
    if (props.otherPointer != false) {
      const new_degree = get_doubleSplitcomplementary_pointer_degree(
        props.otherPointer
      );
      props.dispatch(setDoubleSplitComplementDegree(new_degree));
    }
  }, [props.otherPointer]);

  useEffect(() => {
    if (!!props.otherPointer) {
      const colors = double_split_complement_otherpointer(props.otherPointer);
      setColorArr(colors);
      props.dispatch(setWheelPointers(colors));
    }
  }, [props.Degree]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <>
      <div>Double Split Compelmentary harmony (4)</div>
      <div className="d-flex">{content}</div>
    </>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    otherPointer: state.mainslice.otherPointer,
    Degree: state.mainslice.doubleSplitComplementDegree,
  };
})(DoubleSplitcomplementary);
