import ColorDiv from "../custom/Palettes/ColorDiv";
import { triad, triad_other_pointer } from "../custom/HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { setWheelPointers } from "../redux/AllSlices/MainSlice";
import FetchFabricBtn from "./FetchFabricBtn";
import FabricListDiv from "./FabricListDiv";

const fabricKey = "triad_fabric";

const Triad = (props) => {
  const dispatch = useDispatch();
  const [colorArr, setColorArr] = useState([]);
  useEffect(() => {
    const colors = triad(props.baseColor);
    setColorArr(colors);
    dispatch(setWheelPointers(colors));
  }, [props.baseColor]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <div className="d-flex">
      <div className="col-6">
        <div className="mb-2 text-center fw-bold">Triad harmony (3)</div>
        <div className="d-flex">{content}</div>
      </div>
      <div className="col-6">
        <div className="text-center">
          <FetchFabricBtn colors={colorArr} statekey={fabricKey} />
        </div>
        {props.fabricData.message.fabrics && (
          <FabricListDiv fabricList={props.fabricData.message.fabrics} />
        )}
      </div>
    </div>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    //otherPointer: state.mainslice.otherPointer,
    fabricData: { ...state.Forms[fabricKey] },
  };
})(Triad);
