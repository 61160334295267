import { useEffect, useState } from "react";
import { web_url, fabric_path } from "../Constants";
const FabricListDiv = (props) => {
  const [fabrics, setFabrics] = useState([]);
  useEffect(() => {
    let fabricDiv =
      props.fabricList?.length > 0 &&
      props.fabricList.map((val, i) => {
        let fabric_row =
          val.length > 0 &&
          val.map((f, k) => {
            return (
              <div key={`fabric${k}`} className="m-2">
                <img
                  src={web_url + fabric_path + "/" + f}
                  style={{ height: "5rem", width: "5rem" }}
                />
              </div>
            );
          });
        return (
          <div key={`fabricRow${i}`} className="col-2 mx-1">
            <span className="text-center" style={{ fontSize: "10px" }}>
              Matched of {i}
            </span>
            {fabric_row}
          </div>
        );
      });
    setFabrics(fabricDiv);
  }, [props.fabricList]);
  return <div className="fabricDiv d-flex text-center">{fabrics}</div>;
};
export default FabricListDiv;
