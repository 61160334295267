import ColorDiv from "./ColorDiv";
import { dark_shades, light_shades } from "../HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row } from "react-bootstrap";
import { setWheelPointers } from "../../redux/AllSlices/MainSlice";

const Shades = (props) => {
  const [colorArr, setColorArr] = useState([]);
  const [colorArr2, setColorArr2] = useState([]);
  useEffect(() => {
    const colors = dark_shades(props.baseColor);
    setColorArr(colors);
    const colors2 = light_shades(props.baseColor);
    setColorArr2(colors2);

    props.dispatch(setWheelPointers(colors));
  }, [props.baseColor]);
  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  let content2 = colorArr2.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <>
      <div>Shades harmony (as many as you want)</div>
      <div>Dark Shades</div>
      <div className="d-flex">{content}</div>
      <div>Light Shades</div>
      <div className="d-flex">{content2}</div>
    </>
  );
};
export default connect((state) => {
  return { baseColor: state.mainslice.baseColor };
})(Shades);
