//import useImageColor from "use-image-color";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { storeImageColorPalette } from "../redux/AllThunk/ImageColors";

const formKey = "storeImageColors";
const ImageColors = (props) => {
  useEffect(() => {}, [props.formResult]);
  // const { colors } = useImageColor(props.image, {
  //   Cors: true,
  //   colors: 7,
  //   windowSize: 50,
  //   format: "rgb",
  // });
  const colors = {};
  let colorstring = "";

  const saveColorPalette = () => {
    const nameArr = props.image.split("/");
    const imgName = nameArr[nameArr.length - 1];
    !!colorstring &&
      props.dispatch(
        storeImageColorPalette({
          payload: {
            name: imgName,
            image: props.image,
            // colors: colorstring,
            colors: {},
          },
          key: formKey,
        })
      );
  };

  return (
    colors && (
      <>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {colors.map((i, index) => {
            colorstring +=
              index !== colors.length - 1 ? `rgb(${i}),` : `rgb(${i})`;

            return (
              <div key={`abc${i}`}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: `rgb(${i})`,
                      width: "5rem",
                      height: "3rem",
                    }}
                  ></div>
                  <div style={{ fontSize: "12px" }}>{`rgb(${i})`}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ alignItems: "center", textAlign: "center" }}>
          {props.formResult.success && (
            <span style={{ color: "green" }}> Saved</span>
          )}
          {props.formResult.error && (
            <span style={{ color: "red" }}> {props.formResult.message}</span>
          )}
          {!!props.formResult.error == false &&
            !!props.formResult.success == false && (
              <button
                className="btn btn-primary text-center"
                onClick={saveColorPalette}
                style={{ height: "2rem" }}
              >
                Save Color Palette
              </button>
            )}
        </div>
      </>
    )
  );
};
export default connect((state) => {
  return { formResult: { ...state.Forms[formKey] } };
})(ImageColors);
