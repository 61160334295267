const GetData = function (
  state = {
    moodboardlist: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    alldbcolors: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    similar_image_result: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
    similar_image_batches: {
      loading: true,
      error: false,
      success: false,
      error_msg: "",
      data: "",
    },
  },
  action
) {
  switch (action.type) {
    case "RESET_GET": {
      state = { ...state };
      state[action.key]["loading"] = true;
      state[action.key]["success"] = false;
      state[action.key]["error"] = false;
      state[action.key]["error_msg"] = "";
      state[action.key]["data"] = "";
      return state;
    }
    case "GET_SUCCESS": {
      state = { ...state };
      state[action.key]["loading"] = false;
      state[action.key]["success"] = true;
      state[action.key]["error"] = false;
      state[action.key]["error_msg"] = "";
      state[action.key]["data"] = action.payload;
      return state;
    }
    case "GET_ERROR": {
      state = { ...state };
      state[action.key]["loading"] = false;
      state[action.key]["success"] = false;
      state[action.key]["error"] = true;
      state[action.key]["data"] = "";
      state[action.key]["error_msg"] = action.payload;
      return state;
    }
    default:
      return state;
  }
};

export default GetData;
