import { connect } from "react-redux";
import { returnFormat } from "../HarmonyFuctions";
import { getMatchingFabric } from "../../redux/AllThunk/DbColos";

const fabricKey = "colorBasedFabrics";

const ColorDiv = (props) => {
  const get_machingFabric = (color) => {
    props.dispatch(
      getMatchingFabric({
        payload: {
          colors: [color],
        },
        key: fabricKey,
      })
    );
  };

  return (
    <div
      className="col-2 mx-1"
      onClick={() => {
        get_machingFabric(props.bgcolor);
      }}
      key={props.key}
    >
      <div
        style={{
          display: "inline-block",
          width: "100%",
          height: "100px",
          background: props.bgcolor,
        }}
      >
        {props.sequence}
      </div>
      <div
        style={{
          color: "grey",
          fontSize: "11px",
          margin: "5px",
          padding: "2px",
        }}
      >
        {props.bgcolor}
        {props.baseColor == props.bgcolor && "-Base"}
        <br />
        {returnFormat(props.bgcolor, "hsl")}
      </div>
    </div>
  );
};
export default connect((state) => {
  return { baseColor: state.mainslice.baseColor };
})(ColorDiv);
