import * as Yup from "yup";

export const initialValues = {
  filename: "",
  multipleFiles:[]
};

export const cav_initialValues = {
  file: "",
};

export const imageTypes = [
  "image/jpg",
  "image/jpeg",
  "image/png",
];


export const cav_validationSchema = Yup.object().shape({
  //filename: Yup.string().required("Required"),
  
  file:Yup.mixed().required("Required")
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
      if (value !== undefined) {
        return imageTypes.includes(value.type);
      } else {
        return true;
      }
    })
    .test("fileSize", "Your Document is too big ", (value) => {
      if (value !== undefined) {
        var filesize = value && Math.round(value.size / 1024);
        return filesize && filesize < 3072;
      } else {
        return true;
      }
    }),
});

export const validationSchema = Yup.object().shape({
  //filename: Yup.string().required("Required"),
  /*
  filename:Yup.mixed().required("Required")
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
      if (value !== undefined) {
        return imageTypes.includes(value.type);
      } else {
        return true;
      }
    })
    .test("fileSize", "Your Document is too big ", (value) => {
      if (value !== undefined) {
        var filesize = value && Math.round(value.size / 1024);
        return filesize && filesize < 3072;
      } else {
        return true;
      }
    }),
    */
    // biomedicalQty: Yup.array().of(
    //   Yup.object().shape({
    //     file: Yup.mixed()
    //       .optional()
    //       .test("fileSize", "Your Document is too big ", (value) => {
    //         if (value !== undefined) {
    //           var filesize = value && Math.round(value.size / 1024);
    //           return filesize && filesize < 3072;
    //         } else {
    //           return true;
    //         }
    //       })
    //       .test(
    //         "FILE_FORMAT",
    //         "Uploaded file has unsupported format.",
    //         (value) => {
    //           if (value !== undefined) {
    //             return PDF_FORMATS.includes(value.type);
    //           } else {
    //             return true;
    //           }
    //         }
    //       ),
    //   })
    // ),
    multipleFiles:Yup.mixed().required("Required")
    // .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
    //   if (value !== undefined) {
    //     console.log('v',value);
    //     value.map((v)=>{
    //       console.log('--')
    //     })
    //     // var files = Object.keys(value).map(function(key) {
    //     //   return imageTypes.includes(value[key].type);
    //     // });
    //     //return files.includes(false)?false:true;
    //   } else {
    //     return true;
    //   }
    // })
    // .test("fileSize", "Your Document is too big ", (value) => {
    //   if (value !== undefined) {
    //     var filesize = value && Math.round(value.size / 1024);
    //     return filesize && filesize < 3072;
    //   } else {
    //     return true;
    //   }
    // }),
});
