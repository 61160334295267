import ColorDiv from "./ColorDiv";
import {
  split_complement,
  get_splitcomplementary_pointer_degree,
  split_complement_otherpointer,
} from "../HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import {
  setWheelPointers,
  setSplitComplementaryDegree,
} from "../../redux/AllSlices/MainSlice";

const Splitcomplementary = (props) => {
  const [colorArr, setColorArr] = useState([]);
  useEffect(() => {
    const colors = split_complement(props.baseColor);
    setColorArr(colors);
    props.dispatch(setWheelPointers(colors));

    get_splitcomplementary_pointer_degree({
      color: props.baseColor,
      pointer_index: 0,
    });
  }, [props.baseColor]);

  useEffect(() => {
    if (props.otherPointer != false) {
      const colors = split_complement_otherpointer(props.otherPointer);
      setColorArr(colors);
      props.dispatch(setWheelPointers(colors));
    }
  }, [props.splitComplementDegree]);

  useEffect(() => {
    if (props.otherPointer != false) {
      const new_degree = get_splitcomplementary_pointer_degree(
        props.otherPointer
      );
      props.dispatch(setSplitComplementaryDegree(new_degree));
    }
  }, [props.otherPointer]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <>
      <div>Split Compelmentary harmony (3)</div>
      <div className="d-flex">{content}</div>
    </>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    otherPointer: state.mainslice.otherPointer,
    splitComplementDegree: state.mainslice.splitComplementDegree,
  };
})(Splitcomplementary);
