import { useEffect, useRef, useState } from "react";
import axios from "../redux/Interceptor";
import { api_url, HTTP_OK } from "../Constants";
//import { web_url, fabric_path } from "../Constants";
import { web_url } from "../Constants";
import React from "react";

const fabricKey = "moodBoard";
const Fabrics = (props) => {
  const [colorArr, setColorArr] = useState([]);
  const [fabricData, setFabricData] = useState("");
  const [fabric_path, setFabricPath] = useState("");
  const [response, setResponse] = useState("");
  // const [inputVal, setInputVal] = useState(5);
  const diffRef = useRef();

  useEffect(() => {
    let rgbaArr = props.ele.map((e, i) => {
      return `rgba(${e.r},${e.g},${e.b},1)`;
    });
    setColorArr(rgbaArr);
  }, []);

  let colors = props.ele.map((e, i) => {
    const color = `rgba(${e.r},${e.g},${e.b},1)`;
    return (
      <div
        style={{
          display: "inline-block",
          width: "5rem",
          height: "5rem",
          background: color,
        }}
        key={`moodcolor` + i}
      ></div>
    );
  });

  const get_machingFabric = () => {
    axios({
      url: api_url + "getColorBasedFabrics",
      method: "post",
      data: {
        colors: colorArr,
        diff: diffRef.current.value,
      },
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          setFabricData(response?.data?.data.fabrics);
          setFabricPath(response?.data?.data.path);
          setResponse(response?.data?.data);
        } else {
          //   dispatch({
          //     type: "FORM_ERROR",
          //     payload: response?.response?.data,
          //     key: action.key,
          //   });
        }
      },
      (error) => {
        // dispatch({
        //   type: "FORM_ERROR",
        //   payload: error?.message,
        //   key: action.key,
        // });
      }
    );
  };

  return (
    <>
      <div className="d-flex">
        <input
          type="text"
          placeholder="Difference"
          className="m-2"
          style={{ height: "2rem" }}
          defaultValue={5}
          onChange={(event) => {
            //setInputVal(event.target.value);
          }}
          ref={diffRef}
        />
        <button
          onClick={() => {
            get_machingFabric();
          }}
          className="m-2"
          style={{ height: "2rem" }}
        >
          Get Fabrics
        </button>
      </div>
      <div className="d-flex">{colors}</div>
      <div className="d-flex">
        {fabricData.length > 0 &&
          fabricData.map((e, i) => {
            return (
              <React.Fragment key={`d${i}`}>
                {e.length > 0 ? (
                  <div key={`d${i}`} className="d-flex flex-column">
                    {e.map((v, j) => {
                      return (
                        <div
                          key={`fabricimg${j}`}
                          className="d-flex flex-column"
                        >
                          <div>
                            img:{response?.image_ids?.[i]?.[j]} c:
                            {response?.color_index?.[i]?.[j]}
                          </div>
                          <div>
                            <img
                              src={web_url + fabric_path + "/" + v}
                              style={{ height: "5rem", width: "5rem" }}
                              className="mt-1"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div key={`d${i}`} style={{ height: "5rem", width: "5rem" }}>
                    NA
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </div>
    </>
  );
};
export default Fabrics;
