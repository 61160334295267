import { Formik, FormikProvider, Field, useFormik,useField } from "formik";
import { Button, Container, Form, Table } from "react-bootstrap";
import { initialValues, validationSchema } from "./Util";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { uploadSimilarImages,getSimilarImageResult } from "../../redux/AllThunk/SimilarImages";
import Thumb2 from "./Thumb2";
import logo from "../../assets/images/logo_white.png";
import MatchResult from "./MatchResult";
import { Link } from "react-router-dom";

const formKey = "similar_images";
const resultKey = "similar_image_result";

const Similarimages = (props) => {

  const [file, setFile] = useState("");
  const[batch_no,setBatch_no] = useState("");
  const[fetchCount,setFetchCount] = useState(0);
  const[result,setResult] = useState({});
  const[imgPath,setImgPath] = useState("");

  const multifileRef = useRef();

  const submitHandler = (values) => {
    if (formik.isValid === true) {
      let formData = new FormData();

      if(values.multipleFiles.length>0){
        Object.keys(values.multipleFiles).map((key) => { 
          formData.append(`files[${key}]`, values.multipleFiles[key])});

          props.dispatch(
            uploadSimilarImages({
              payload:formData,
              key: formKey,
            })
          );
      }else{
        formik.setFieldError("multipleFiles","Please select files");
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {

    if (props.formResult.success === true) {
      setResult(props.formResult.message);
     /*  setBatch_no(props.formResult.message.path);
      
      setTimeout(() => {
        formik.resetForm();

        //fetchResult(props.formResult.message.path);
        setTimeout(() => {
          setFetchCount(1);
          props.dispatch({
            type: "RESET_FORM",
            key: formKey,
          });
        }, 5000);
      }, 5000); */
    }
  }, [props?.formResult]);

/*   const fetchResult = (batchNo)=>{

    const batchnumber =batchNo?batchNo:batch_no
    //const batchnumber = "batch_6";
    console.log('batchnumber',batchnumber);
    props.dispatch(
      getSimilarImageResult({
        payload:{batchnumber},
        key: resultKey,
      })
    );
  } */

/*   useEffect(()=>{
    props?.imagesResult?.data.data !==undefined && setResult(props?.imagesResult?.data.data);
    props?.imagesResult?.data.imgUrl!==undefined && setImgPath(props?.imagesResult?.data.imgUrl);
  },[props?.imagesResult?.data]); */

  // useEffect(()=>{
  //   console.log('fetchcount',fetchCount);
  //   setTimeout(() => {
  //     if(fetchCount>0 && fetchCount<5 && Object.keys(result).length==0){
  //       fetchResult();

  //       setTimeout(()=>{
  //         setFetchCount(fetchCount+1);
  //       },10000)
  //     }
  //   }, 30000);
  // },[fetchCount])
  //console.log('re',result);

  return (
    <div  //style={{backgroundColor:"#e9ecef"}}
    >
    <div style={{background:"#343a40",color:"white"}} className="px-5 py-2">
      <Link to="/"><img src={logo} style={{widht:"2rem",height:"2rem"}} />
      <b className="ms-2">Similar Images</b>
      </Link>
    </div>
    
    <Container fluid className="mt-5">
      
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div  className="col-4 d-flex">
              <Form.Control
                type="file"
                autoComplete="off"
                isInvalid={!!formik.errors.filename && !!formik.touched.filename}
                ref={multifileRef}
                onChange={(e) => {
                  formik.setFieldValue("multipleFiles",e.target.files);
                  setFile(URL.createObjectURL(e.target.files[0]));
                  setResult({});
                }}
                accept=".jpg,.jpeg,.png"
                multiple
                className="me-1"
              />
              { formik.errors.multipleFiles && (
                <Form.Control.Feedback type="invalid" style={{display:"block"}}>
                  {formik.errors.multipleFiles}
                </Form.Control.Feedback>
              )}
            
              <Button className="button" variant="primary" type="submit">
                submit
              </Button>
            </div>
            {file.length>0 && (
                <div className="col-2 m-2">
                <img src={file} style={{width:'12rem',height:'12rem'}} />
                </div>
            )}
            {/* <div className="col-2 text-center">
              <Button className="button" variant="success" type="button" onClick={()=>fetchResult()}>
                Fetch Result
              </Button> 
            </div> */}
          </Form>
        </FormikProvider>

        <div className="mt-2">
          
          {Object.keys(result).length >0 && (

            <MatchResult result={result} />
          )}
        </div>
    </Container>
    </div>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[formKey] },
    //imagesResult: {...state?.GetData?.[resultKey] }
  };
})(Similarimages);
