import ColorDiv from "../custom/Palettes/ColorDiv";
import {
  split_complement,
  get_splitcomplementary_pointer_degree,
  split_complement_otherpointer,
} from "../custom/HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import {
  setWheelPointers,
  setSplitComplementaryDegree,
} from "../redux/AllSlices/MainSlice";
import FetchFabricBtn from "./FetchFabricBtn";
import FabricListDiv from "./FabricListDiv";

const fabricKey = "splitCompliment_fabric";

const Splitcomplementary = (props) => {
  const [colorArr, setColorArr] = useState([]);
  useEffect(() => {
    const colors = split_complement(props.baseColor);
    setColorArr(colors);
    props.dispatch(setWheelPointers(colors));

    // get_splitcomplementary_pointer_degree({
    //   color: props.baseColor,
    //   pointer_index: 0,
    // });
  }, [props.baseColor]);

  //   useEffect(() => {
  //     if (props.otherPointer != false) {
  //       const colors = split_complement_otherpointer(props.otherPointer);
  //       setColorArr(colors);
  //       props.dispatch(setWheelPointers(colors));
  //     }
  //   }, [props.splitComplementDegree]);

  //   useEffect(() => {
  //     if (props.otherPointer != false) {
  //       const new_degree = get_splitcomplementary_pointer_degree(
  //         props.otherPointer
  //       );
  //       props.dispatch(setSplitComplementaryDegree(new_degree));
  //     }
  //   }, [props.otherPointer]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <div className="d-flex">
      <div className="col-6">
        <div className="mb-2 text-center fw-bold">
          Split Compelmentary harmony (3)
        </div>
        <div className="d-flex">{content}</div>
      </div>
      <div className="col-6">
        <div className="text-center">
          <FetchFabricBtn colors={colorArr} statekey={fabricKey} />
        </div>
        {props.fabricData.message.fabrics && (
          <FabricListDiv fabricList={props.fabricData.message.fabrics} />
        )}
      </div>
    </div>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    fabricData: { ...state.Forms[fabricKey] },

    // otherPointer: state.mainslice.otherPointer,
    // splitComplementDegree: state.mainslice.splitComplementDegree,
  };
})(Splitcomplementary);
