import { parseColor } from "@react-stately/color";
import { useEffect, useState } from "react";
import { Provider, lightTheme, Flex } from "@adobe/react-spectrum";
import { ColorWheel, ColorSlider } from "@react-spectrum/color";
import { Row, Container } from "react-bootstrap";

import { TinyColor } from "@ctrl/tinycolor";
import CustomColorWheel from "./CustomColorWheel";
import Analogouos from "./Palettes/Analogous";
import Monochromatic from "./Palettes/Monochromatic";
import Triad from "./Palettes/Triad";
import Complementary from "./Palettes/Complementary";
import SplitComplementary from "./Palettes/SplitComplementary";

function Tinycolor() {
  //===================================

  let [mainColor, setMainColor] = useState(parseColor("hsl(135, 89%, 54%)"));
  let [alphaColor, setAlphaColor] = useState(
    parseColor(mainColor.toString("rgba"))
  );
  let [blueColor, setBlueColor] = useState(
    parseColor(mainColor.toString("rgba"))
  );
  let [redColor, setRedColor] = useState(
    parseColor(mainColor.toString("rgba"))
  );
  let [greenColor, setGreenColor] = useState(
    parseColor(mainColor.toString("rgba"))
  );
  let [wheelcolor, setWheelcolor] = useState(
    parseColor(mainColor.toString("hsl"))
  );
  let [visiblePalette, setVisiblePalette] = useState("Analogous");

  let colorRgba = parseColor(mainColor.toString("rgba"));

  let tinycolor = new TinyColor(
    "rgba(" +
      colorRgba.red +
      "," +
      colorRgba.green +
      "," +
      colorRgba.blue +
      "," +
      colorRgba.alpha +
      ")"
  );

  useEffect(() => {
    setWheelcolor(parseColor(mainColor.toString("hsl")));
    setAlphaColor(parseColor(mainColor.toString("rgba")));
    setBlueColor(parseColor(mainColor.toString("rgba")));
    setRedColor(parseColor(mainColor.toString("rgba")));
    setGreenColor(parseColor(mainColor.toString("rgba")));

    // const monochrom = getMonochromaticPalette();
    // setMonochromatic_p(monochrom);
  }, [mainColor]);

  const [inputval, setInputVal] = useState();

  const methods = [
    "Analogous",
    "Monochromatic",
    "Triad",
    "Complementary",
    "Split Complementary",
    "Double Split Complementary",
    "Square",
    "Compound",
    "Shades",
  ];
  let radios = methods.map((i, e) => {
    return (
      <div className="col" key={`radio` + i}>
        <input
          type="radio"
          name="method"
          value={i}
          className="m-1"
          checked={i == visiblePalette ? true : false}
          onChange={(val) => {
            setVisiblePalette(val.target.value);
          }}
        />
        {i}
      </div>
    );
  });

  const wheelcolors = [
    "rgba(255, 0, 0,0.1)",
    "rgba(0, 255, 0,0)",
    "rgba(0, 0, 255,1)",
  ];

  return (
    <>
      <Container fluid>
        <Provider theme={lightTheme}>
          <Row>
            <div className="col-4">
              <div>Apply Color Harmony Rule</div>
              <div className="d-flex flex-column">{radios}</div>
            </div>
            <div className="col-4">
              <CustomColorWheel
                value={wheelcolor}
                onChange={(val) => {
                  setMainColor(val);
                }}
                aria-labelledby="label-4"
                size="size-1600"
              />
            </div>
            <div className="col-4">
              <div>Base Color: {mainColor.toString("hex")}</div>
              <div>
                <input
                  type="text"
                  name="base_color"
                  onChange={(val) => {
                    setInputVal(val.target.value);
                  }}
                  placeholder="Enter your base color"
                  className="form-control col-4"
                />
                <button
                  onClick={() => {
                    var tiny = new TinyColor(inputval);
                    setMainColor(parseColor(tiny.toString("hex")));
                  }}
                >
                  Set base color
                </button>
              </div>
            </div>
            {/* <div className="col-4">
              <ColorWheel
                value={wheelcolor}
                onChange={(val) => {
                  setMainColor(val);
                }}
                aria-labelledby="label-1"
              />
            </div> */}
          </Row>

          {visiblePalette == "Analogous" && (
            <Row className="mt-3">
              <div className="col-2">Analogous</div>
              <div className="col-10 d-flex">
                <Analogouos tinycolor={tinycolor} />
              </div>
            </Row>
          )}
          {visiblePalette == "Monochromatic" && (
            <Row className="mt-3">
              <div className="col-2">Monochromatic</div>
              <div className="col-10 d-flex">
                <Monochromatic tinycolor={tinycolor} />
              </div>
            </Row>
          )}
          {visiblePalette == "Triad" && (
            <Row className="mt-3">
              <div className="col-2">Triad</div>
              <div className="col-10 d-flex">
                <Triad tinycolor={tinycolor} />
              </div>
            </Row>
          )}
          {visiblePalette == "Complementary" && (
            <Row className="mt-3">
              <div className="col-2">Complementary</div>
              <div className="col-10 d-flex">
                <Complementary tinycolor={tinycolor} />
              </div>
            </Row>
          )}
          {visiblePalette == "Split Complementary" && (
            <Row className="mt-3">
              <div className="col-2">Split Complementary</div>
              <div className="col-10 d-flex">
                <SplitComplementary tinycolor={tinycolor} />
              </div>
            </Row>
          )}
          {visiblePalette == "Double Split Complementary" && (
            <Row className="mt-3">
              <div className="col-2">Double Split Complementary</div>
              <div className="col-10 d-flex">
                <Complementary tinycolor={tinycolor} />
              </div>
            </Row>
          )}
          <Row className="mt-2">
            <div className="col-4">
              <Flex direction="column">
                <ColorSlider
                  channel="red"
                  value={redColor}
                  onChange={(val) => {
                    setMainColor(val);
                  }}
                />
                <ColorSlider
                  channel="green"
                  value={greenColor}
                  onChange={(val) => {
                    setMainColor(val);
                  }}
                />
                <ColorSlider
                  channel="blue"
                  value={blueColor}
                  onChange={(val) => {
                    setMainColor(val);
                  }}
                />
                <ColorSlider
                  channel="alpha"
                  value={alphaColor}
                  onChange={(val) => {
                    setMainColor(val);
                  }}
                />
              </Flex>
            </div>
          </Row>
        </Provider>
      </Container>
    </>
  );
}

export default Tinycolor;
