import ColorDiv from "./ColorDiv";
import { complement, complement_other_pointer } from "../HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import { setWheelPointers } from "../../redux/AllSlices/MainSlice";

const Complementary = (props) => {
  const [colorArr, setColorArr] = useState([]);
  useEffect(() => {
    const colors = complement(props.baseColor);
    setColorArr(colors);
    props.dispatch(setWheelPointers(colors));
  }, [props.baseColor]);

  useEffect(() => {
    if (props.otherPointer !== false) {
      const colors = complement(props.otherPointer.color);
      //const colors = complement_other_pointer(props.otherPointer);
      setColorArr(colors);
      props.dispatch(setWheelPointers(colors));
    }
  }, [props.otherPointer]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <>
      <div>Compelmentary harmony (2)</div>
      <div className="d-flex">{content}</div>
    </>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    otherPointer: state.mainslice.otherPointer,
  };
})(Complementary);
