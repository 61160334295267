import ColorDiv from "../custom/Palettes/ColorDiv";
import { dark_shades, light_shades } from "../custom/HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { setWheelPointers } from "../redux/AllSlices/MainSlice";
import FetchFabricBtn from "./FetchFabricBtn";
import FabricListDiv from "./FabricListDiv";

const fabricKey = "light_shade_fabric";
const dark_fabricKey = "dark_shade_fabric";

const Shades = (props) => {
  const [colorArr, setColorArr] = useState([]);
  const [colorArr2, setColorArr2] = useState([]);
  useEffect(() => {
    const colors = dark_shades(props.baseColor);
    setColorArr(colors);
    const colors2 = light_shades(props.baseColor);
    setColorArr2(colors2);

    props.dispatch(setWheelPointers(colors));
  }, [props.baseColor]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  let content2 = colorArr2.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <>
      <div className="d-flex">
        <div className="col-6">
          <div className="mb-2 text-center fw-bold">Dark Shades</div>
          <div className="d-flex">{content2}</div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <FetchFabricBtn colors={colorArr2} statekey={dark_fabricKey} />
          </div>
          {props.fabricData2.message.fabrics && (
            <FabricListDiv fabricList={props.fabricData2.message.fabrics} />
          )}
        </div>
      </div>
      <div className="d-flex">
        <div className="col-6">
          <div className="mb-2 text-center fw-bold">Light Shades</div>
          <div className="d-flex">{content}</div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <FetchFabricBtn colors={colorArr} statekey={fabricKey} />
          </div>
          {props.fabricData.message.fabrics && (
            <FabricListDiv fabricList={props.fabricData.message.fabrics} />
          )}
        </div>
      </div>
    </>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    fabricData: { ...state.Forms[fabricKey] },
    fabricData2: { ...state.Forms[dark_fabricKey] },
  };
})(Shades);
