import {multipart_instance as axios} from "../Interceptor";
import { api_url, HTTP_OK,similarMatchAPI } from "../../Constants";

export const uploadSimilarImages = (action) => {

  return async (dispatch) => {  
     await axios({
      url: api_url + "uploadSimilarImages",
      method: "post",
      data: action.payload,
      headers:{'Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'Access-Control-Allow-Headers'}
    }).then(
      (response) => {
        //console.log('res>',response);
        if (response.status == HTTP_OK) {

          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    ); 
    
  };
};

export const cavmatchApi = (action) => {

  return (dispatch) => {  
    axios({
      url: "http://164.52.220.216:8888/cavmatch",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );
    
  };
};

export const getSimilarImageResult = (action)=>{
  return (dispatch) => {  
    axios({
      url: api_url + "getSimilarImageResult",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            //payload: response?.data.data,
            payload: {data:response?.data.data,imgUrl:response?.data.similar_img_path},
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );
  };
};

export const getBatchList = (action)=>{
  return (dispatch) => {  
    axios({
      url: api_url + "getSimilarImageBatches",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );
  };
}

export const getMoodMatches = (action) => {

  return async (dispatch) => {  
     await axios({
      url: api_url + "getMoodMatches",
      method: "post",
      data: action.payload,
      headers:{'Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'Access-Control-Allow-Headers'}
    }).then(
      (response) => {
        //console.log('res>',response);
        if (response.status == HTTP_OK) {

          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    ); 
    
  };
};

