import {  FormikProvider,  useFormik } from "formik";
import { Button, Container, Form, Table } from "react-bootstrap";
import { initialValues, validationSchema } from "./Util";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getMoodMatches } from "../../redux/AllThunk/SimilarImages";
import logo from "../../assets/images/logo_white.png";
import MatchResult from "./MatchResult";
import { Link } from "react-router-dom";
import Thumb2 from "./Thumb2";
import { useDispatch } from "react-redux";

const formKey = "mood_matches"; 

const MoodMatch = (props) => {

  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const[result,setResult] = useState({});

  const multifileRef = useRef();

  const submitHandler = (values) => {
    if (formik.isValid === true) {
      let formData = new FormData();

      if(values.multipleFiles.length>0){
        Object.keys(values.multipleFiles).map((key) => { 
          formData.append(`files[${key}]`, values.multipleFiles[key])});

          props.dispatch(
            getMoodMatches({
              payload:formData,
              key: formKey,
            })
          );
      }else{
        formik.setFieldError("multipleFiles","Please select files");
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {

    if (props.formResult.success === true) {
      setResult(props.formResult.message);

    }
  }, [props?.formResult]);

  return (
    <div>
    <div style={{background:"#343a40",color:"white"}} className="px-5 py-2">
      <Link to="/"><img src={logo} style={{widht:"2rem",height:"2rem"}} />
      <b className="ms-2">Mood Matches</b>
      </Link>
    </div>
    
    <Container fluid className="mt-5">
      
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div  className="col-4 d-flex">
              <Form.Control
                type="file"
                autoComplete="off"
                isInvalid={!!formik.errors.filename && !!formik.touched.filename}
                ref={multifileRef}
                onChange={(e) => {
                  formik.setFieldValue("multipleFiles",e.target.files);
                  setFile(URL.createObjectURL(e.target.files[0]));
                  dispatch({
                    type: "RESET_FORM",
                    key: formKey,
                  });
                }}
                accept=".jpg,.jpeg,.png"
                multiple
                className="me-1"
              />
              { formik.errors.multipleFiles && (
                <Form.Control.Feedback type="invalid" style={{display:"block"}}>
                  {formik.errors.multipleFiles}
                </Form.Control.Feedback>
              )}
            
              <Button className="button" variant="primary" type="submit">
                submit
              </Button>
            </div>
          </Form>
        </FormikProvider>

        <div className="mt-2 d-flex">
          <div className="col-3">
            {file.length>0 && (
                <div className="col-2 m-2">
                <img src={file} style={{width:'20rem',height:'20rem'}} />
                </div>
            )}
          </div>
          <div className="col-9">
          {props.formResult.success === true && props.formResult.message.length >0 && 

              props.formResult.message.map((val,key) => { 
              return <div className="col-12" key={key}>
                { val.map((img,index)=>{
                  return <Thumb2 selectedImg={img} key={index+key} />
                })
              }
              </div>

            })
          }
          </div>
        </div>
    </Container>
    </div>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[formKey] },
  };
})(MoodMatch);
