import Thumb2 from "./Thumb2";
import React from "react";

const WrapperComponent = ({ children }) => {
    const groupSize = 8;
  
    const createWrappers = (children) => {
      const wrappers = [];
      for (let i = 0; i < children.length; i += groupSize) {
        wrappers.push(children.slice(i, i + groupSize));
      }
      return wrappers;
    };
  
    const wrappers = createWrappers(React.Children.toArray(children));
  
    return (
      <div>
        {wrappers.map((wrapper, index) => (
          <div key={index} className="wrapper" style={{}}>
            {wrapper}
          </div>
        ))}
      </div>
    );
  };

const MatchResult = ({result})=>{
   
    return <WrapperComponent>
    {
        Object.entries(result).map((val,key) => { 
            return (
            <Thumb2 selectedImg={val[1]} />
            )
        })
    }
    </WrapperComponent>

}
export default MatchResult