import { Container, Button } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import { connect } from "react-redux";
import IroWheel from "./IroWheel";
import Analogous from "./Analogous";
import {
  setBaseColor,
  setOtherPointerMoved,
  setActiveHarmony,
} from "../redux/AllSlices/MainSlice";
import Triad from "./Triad";
import Complementary from "./Complementary";
import Compound from "./Compound";

import DoubleSplitComplementary from "./DoubleSplitComplementary";
import SplitCompelmentary from "./SplitCompelmentary";
import Monochromatic from "./Monochromatic";
import Shades from "./Shades";
import Square from "./Square";

import { useEffect, useState } from "react";
import { getDbColors } from "../redux/AllThunk/DbColos";
import ColorDiv from "../custom/Palettes/ColorDiv";
import { web_url } from "../Constants";
import { TinyColor } from "@ctrl/tinycolor";
import { parseColor } from "@react-stately/color";
//import MoodBoardDiv from "./MoodBoardDiv";
import MoodBoardDiv2 from "./MoodBoardDiv2";

const formKey = "dbFabricColors";
const fabricKey = "colorBasedFabrics";

const FinalPalettes = (props) => {
  const [dbColors, setDbColors] = useState("");
  const [moodboard_palette, setMoodboard_palette] = useState("");
  const [loading, setLoading] = useState(false);
  // const [fabricList, setFabricList] = useState("");

  const formik = useFormik({
    initialValues: {
      colorInput: props.baseColor,
    },
  });

  useEffect(() => {
    const tiny = new TinyColor(formik.values.colorInput);
    const a = parseColor(tiny.toString("rgba"));
    props.dispatch(setBaseColor(a.toString("rgba")));
  }, [formik.values.colorInput]);

  const BaseColorChangeHander = (value) => {
    props.dispatch(setBaseColor(value));
  };

  const getFromdb = () => {
    props.dispatch(
      getDbColors({
        payload: {
          color: props.baseColor,
        },
        key: formKey,
      })
    );
  };

  useEffect(() => {
    let content =
      props.formResult.success == true &&
      props.formResult.message.colors.map((e, i) => {
        const color = `rgba(${e.r},${e.g},${e.b},1)`;
        return <ColorDiv bgcolor={color} key={`color` + i} sequence={i} />;
      });

    const moodboardColors = props.formResult.message.moodboardColors;

    if (moodboardColors) {
      let mood_board_palette = moodboardColors.map((ele, i) => {
        return <MoodBoardDiv2 ele={ele} i={i} key={`moodboardMasterDiv${i}`} />;
      });

      setMoodboard_palette(mood_board_palette);
    }
    setDbColors(content);
  }, [props.formResult]);

  //useEffect(() => {
  //console.log("--soniyaprops.baseColor", props.baseColor);
  //getFromdb();
  //}, [props.baseColor]);

  // useEffect(() => {
  //   if (props.fabricData.success == true) {
  //     const path = props.fabricData.message.path;
  //     let fabrics = props.fabricData.message.fabrics.map((val, i) => {
  //       return (
  //         <div key={`fabric${i}`} className="m-2">
  //           <img
  //             src={web_url + path + "/" + val}
  //             style={{ height: "15rem", width: "15rem" }}
  //           />
  //         </div>
  //       );
  //     });
  //     setFabricList(fabrics);
  //   } else {
  //     setFabricList("");
  //   }
  // }, [props.fabricData]);

  return (
    <>
      <Container fluid>
        <FormikProvider value={formik}>
          <div style={{ overflowX: "scroll" }}>
            Select Color and Get Palette options
            <IroWheel
              BaseColorChangeHander={BaseColorChangeHander}
              getFromdb={getFromdb}
            />
            <br />
            <br />
            {/* <Button onClick={getFromdb}>
                Get Fabrics and moodboard colors
              </Button>
              <br />
              <br /> */}
            <div className="moodboard_palette">{moodboard_palette}</div>
            <br />
            <br />
            <div>Colors Found in DB</div>
            <div className="d-flex">
              {dbColors}
              {props.formResult.message.colors?.length == 0 && (
                <b>Colors Not Found</b>
              )}
            </div>
            <br />
            <br />
            <Analogous />
            <br />
            <br />
            <Triad />
            <br />
            <br />
            <Complementary />
            <br />
            <br />
            <Compound />
            <br />
            <br />
            <DoubleSplitComplementary />
            <br />
            <br />
            <Square />
            <br />
            <br />
            <SplitCompelmentary />
            <br />
            <br />
            <Monochromatic />
            <br />
            <br />
            <Shades />
            <br />
            <br />
          </div>
          {/* <div className="col-5">
            <h2>Fabrics</h2>
            <div className="d-flex">{fabricList}</div>
          </div> */}
        </FormikProvider>
      </Container>
    </>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    formResult: { ...state.Forms[formKey] },
    fabricData: state.Forms[fabricKey],
  };
})(FinalPalettes);
