//import useImageColor, { Image } from "use-image-color";
import React, { useState } from "react";
import imgone from "../images/one.jpg";
import imgtwo from "../images/two.jpg";
import imgthree from "../images/three.jpg";
import imgfour from "../images/four.jpg";
import imgfive from "../images/five.jpg";
import imgsix from "../images/six.jpeg";
import imgseven from "../images/seven.jpeg";
import imgeight from "../images/eight.jpeg";
import imgnine from "../images/nine.jpg";
import { connect } from "react-redux";

import ImageColors from "./ImageColors";

const ColorExtractor = () => {
  const ws = {
    margin: 3,
    width: "100%",
    height: "100%",
  };

  //tried links but received cors error

  const [imgArr] = useState([
    //imgone,
    imgtwo,
    // imgthree,
    // imgfour,
    // imgfive,
    // imgsix,
    // imgseven,
    // imgeight,
    // imgnine,
    // "https://drive.google.com/file/d/1uEQKVG6Efw0uNnOS7WV_p3CnYZZ94uZt/view",
    // "https://atelio-test-bucket.s3.ap-south-1.amazonaws.com/uploads/designer_snapshot/XuJEUZ8QTY84u216JYbe2DxpG9XjsBRbUEN5PnfM.jpg",
    // "http://willyourwish.test:8888/assets-icon/art.png",
    //"http://willyourwishapi.ainocular.com/assets-icon/art.png",
    "http://colorbackend.test:8888/moodboards/one.jpg",
  ]);

  return (
    <>
      color extract from image
      <div>
        {imgArr.map((img, key) => {
          return (
            <div style={{ borderBottom: "1px solid" }} key={`img${key}`}>
              <div style={{ display: "flex" }}>
                <div className="col-2">
                  {/* <Image wrapperStyle={ws} src={img} /> */}
                </div>

                <div className="col-10">
                  <ImageColors image={img} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default connect((state) => {
  return {};
})(ColorExtractor);
