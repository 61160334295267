import axios from "../Interceptor";
import { api_url, HTTP_OK } from "../../Constants";

export const getMoodboardlist = (action) => {
  return (dispatch) => {
    axios({
      // url: api_url + "getMoodboardlist?page=" + action.payload,
      url: api_url + "getMoodboardlist?" + action.payload,
      method: "get",
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );
  };
};
