import { Formik, FormikProvider, useFormik } from "formik";
import { Button, Container, Form } from "react-bootstrap";
import { initialValues, validationSchema } from "./Util";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { uploadSimilarImages } from "../../redux/AllThunk/SimilarImages";
import Thumb2 from "./Thumb2";
import logo from "../../assets/images/logo_white.png";
const formKey = "similar_images";

const SingleUpload = (props) => {
 
  const [selectedImg, setSelectedImg] = useState("");

  const submitHandler = (values) => {
    if (formik.isValid === true) {
      let formData = new FormData();
      formData.append(`file`, values.filename);

      if(values.multipleFiles.length>0){
        Object.keys(values.multipleFiles).map((key) => { 
          formData.append(`files[${key}]`, values.multipleFiles[key])});
      }
      props.dispatch(
        uploadSimilarImages({
          payload:formData,
          key: formKey,
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    // setLoading(false);

    if (props.formResult.success === true) {
      formik.resetForm();
      setTimeout(() => {
        props.dispatch({
        type: "RESET_FORM",
        key: formKey,
      });
     
        
      }, 3000);
    }
  }, [props?.formResult?.success, props?.formResult?.error]);

  const resetResult = ()=>{
    props.dispatch({
      type: "RESET_FORM",
      key: formKey,
    });
  }


  return (
    <>
    <div style={{background:"#343a40",color:"white",height:"3rem"}} className="px-5 py-2">
      <img src={logo} style={{widht:"2rem",height:"2rem"}} />
      <b className="ms-2">Similar Images</b>
    </div>
    <Container fluid className="mt-5" style={{backgroundColor:"#e9ecef"}}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} className="d-flex">
            <div className="col-4">
              <Form.Label>Single Image</Form.Label>
              <Form.Control
                type="file"
                autoComplete="off"
                isInvalid={!!formik.errors.filename && !!formik.touched.filename}
                //ref={fileRef}
                onChange={(e) => {
                  console.log('fileson',e.target.files[0],e)
                  setSelectedImg(URL.createObjectURL(e.target.files[0]));
                  formik.setFieldValue("filename", e.currentTarget.files[0]);
                  resetResult();
                }}
                accept=".jpg,.jpeg"
              />

              {formik.touched.filename && formik.errors.filename && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.filename}
                </Form.Control.Feedback>
              )}
            </div>
            <div className="col-2 d-flex justify-content-center">
              <Button className="button" variant="primary" type="submit" style={{height:"3rem"}}>
                submit
              </Button>
            </div>
            
          </Form>
        </FormikProvider>
        <div className="mt-4 d-flex">
          <div className="col-2">
            {selectedImg.length>0 && 
            <Thumb2 selectedImg={selectedImg} />
            }
          </div>
          {props?.formResult?.success== true && props?.formResult?.message?.data}
          {console.log('hoo',props?.formResult?.success== true)}

        </div>
    </Container>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[formKey] },
  };
})(SingleUpload);
