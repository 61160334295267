import { Formik, FormikProvider, useFormik } from "formik";
import { Button, Container, Form } from "react-bootstrap";
import { cav_initialValues, cav_validationSchema } from "./Util";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { cavmatchApi } from "../../redux/AllThunk/SimilarImages";
import Thumb2 from "./Thumb2";
import logo from "../../assets/images/logo_white.png";
const formKey = "similar_images_cavmatch";

const CavMatch = (props) => {
 
  const [selectedImg, setSelectedImg] = useState("");

  const submitHandler = (values) => {
    if (formik.isValid === true) {
      let formData = new FormData();
      formData.append(`file`, values.file);

      props.dispatch(
        cavmatchApi({
          payload:formData,
          key: formKey,
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: cav_initialValues,
    values: cav_initialValues,
    validationSchema: cav_validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

console.log('--',props.formResult);
//   useEffect(() => {
//     // setLoading(false);

//     if (props.formResult.success === true) {
//       //Object.keys(props.formResult.message).length>0 && setResult(Object.values(props.formResult.message));
//       //console.log('--',result);
//       setTimeout(() => {
//         //multifileRef.val("");
//         formik.resetForm();
//         props.dispatch({
//           type: "RESET_FORM",
//           key: formKey,
//         });
//       }, 3000);
//     }
//   }, [props?.formResult?.success, props?.formResult?.error]);

  const resetResult = ()=>{
    props.dispatch({
      type: "RESET_FORM",
      key: formKey,
    });
  }


  return (
    <>
    <div style={{background:"#343a40",color:"white",height:"3rem"}} className="px-5 py-2">
      <img src={logo} style={{widht:"2rem",height:"2rem"}} />
      <b className="ms-2">Cavmatch API: upload and get similar images</b>
    </div>
    <Container fluid className="mt-5" style={{backgroundColor:"#e9ecef"}}>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} className="d-flex">
            <div className="col-4">
              <Form.Control
                type="file"
                autoComplete="off"
                isInvalid={!!formik.errors.filename && !!formik.touched.filename}
                //ref={fileRef}
                onChange={(e) => {
                  setSelectedImg(URL.createObjectURL(e.target.files[0]));
                  formik.setFieldValue("file", e.currentTarget.files[0]);
                  resetResult();
                }}
                accept=".jpg,.jpeg"
              />

              {formik.touched.filename && formik.errors.filename && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.filename}
                </Form.Control.Feedback>
              )}
            </div>
            <div className="col-2 text-center">
              <Button className="button" variant="primary" type="submit">
                submit
              </Button>
            </div>
            
          </Form>
        </FormikProvider>
        <div className="mt-4 d-flex">
          <div className="col-2">
            {selectedImg.length>0 && 
            <Thumb2 selectedImg={selectedImg} />
            }
          </div>
        </div>
    </Container>
    </>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[formKey] },
  };
})(CavMatch);
