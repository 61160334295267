import iro from "@jaames/iro";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { Form } from "react-bootstrap";
//import { wheel } from "./WheelUtil";
import { useRef } from "react";
const IroWheel = (props) => {
  const [colorPicker, setcp] = useState(null);

  const wheelElementRef = useRef();

  useEffect(() => {
    if (!!wheelElementRef.current) {
      wheelElementRef.current.innerHTML = "";
      const wheel = new iro.ColorPicker(wheelElementRef.current, {
        width: 260,
        colors: props.wheelpointers,
        //colors: ["rgba(255, 0, 0,1)"],

        handleRadius: 9,
        layout: [
          // default slider, will reflect whichever color is currently active
          {
            component: iro.ui.Wheel,
          },
        ],
      });
      wheel.on(["color:change"], function (color) {
        if (color.index === 0) {
          props.BaseColorChangeHander(color.rgbaString);
        } else {
          props.OtherPointerChangeHandler(color.rgbaString, color.index);
        }
      });
      setcp(wheel);
    }
  }, [wheelElementRef.current]);

  useEffect(() => {
    if (!!colorPicker) {
      //===analogous and monocrhomatic logic
      switch (props.activeHarmony) {
        case "Square":
          colorPicker.setColors(props.wheelpointers);
          break;
        default:
          if (props.otherPointer == false) {
            colorPicker.setColors(props.wheelpointers);
          } else {
            Object.keys(props.wheelpointers).map((i) => {
              //if (props.otherPointer.pointer_index != i && i > 0) {

              if (
                i > 0 &&
                i != props.otherPointer.pointer_index &&
                i != props.otherPointer.pointer_index - 1
              ) {
                colorPicker.removeColor(i);
                colorPicker.addColor(props.wheelpointers[i], i);
              }
            });
          }
          break;
      }
    }
  }, [props.wheelpointers]);

  useEffect(() => {
    if (!!colorPicker) {
      colorPicker.removeColor(0);
      colorPicker.addColor(props.baseColor, 0);
    }
  }, [props.baseColor]);

  return (
    <>
      <div className="mb-3">
        <Field name="colorInput">
          {({ field, meta }) => (
            <>
              <Form.Control
                {...field}
                type="text"
                autoComplete="off"
                //isInvalid={!!meta.error && !!meta.touched}
                value={field.value ? field.value : ""}
              />
            </>
          )}
        </Field>
      </div>
      <div id="colorPicker" ref={wheelElementRef}></div>
    </>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    wheelpointers: state.mainslice.wheelPointers,
    analogousDegree: state.mainslice.analogousDegree,
    otherPointer: state.mainslice.otherPointer,
    activeHarmony: state.mainslice.activeHarmony,
  };
})(IroWheel);
