const Forms = function (
  state = {
    storeImageColors: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    dbFabricColors: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    colorBasedFabrics: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    analogous_fabric: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    triad_fabric: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    compound_fabric: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    doubleSplitCompliment_fabric: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    square_fabric: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    splitCompliment_fabric: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    monochromatic_fabric: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    light_shade_fabric: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    dark_shade_fabric: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    similar_images: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    similar_images_cavmatch: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
    mood_matches: {
      loading: true,
      error: false,
      success: false,
      message: "",
    },
  },
  action
) {
  switch (action.type) {
    case "RESET_FORM": {
      state = { ...state };
      state[action.key]["loading"] = true;
      state[action.key]["success"] = false;
      state[action.key]["error"] = false;
      state[action.key]["message"] = "";
      return state;
    }
    case "FORM_SUCCESS": {
      state = { ...state };
      state[action.key]["success"] = true;
      state[action.key]["error"] = false;
      state[action.key]["message"] = action.payload;
      state[action.key]["loading"] = false;
      //state[action.key]["data"] = action?.data;
      return state;
    }
    case "FORM_ERROR": {
      state = { ...state };
      state[action.key]["success"] = false;
      state[action.key]["error"] = true;
      state[action.key]["message"] = action.payload;
      state[action.key]["loading"] = false;
      return state;
    }
    default:
      return state;
  }
};

export default Forms;
