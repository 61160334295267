export const customPaletteGenerator = "/custom-palettes";
export const tinycolor = "/tiny-color";
export const colorExtractor = "/colorExtractor";
export const generatePalettes = "/generatePalettes";
export const moodboards = "/moodboards";
export const colorgrid = "/colorgrid";
export const similarimages = "/similar-images";
export const draganddrop = "/drag-and-drop";
export const singleupload= "/singleupload";
export const batchList= "/batchlist";
export const cavmatch= "/cavmatch";
export const moodMatch= "/mood-match";
