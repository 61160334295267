import { useEffect, useRef } from "react";
import { getAllDbColors } from "../redux/AllThunk/DbColos";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { getMoodboardlist } from "../redux/AllThunk/Moodboards";

const getKey = "alldbcolors";
const moodboardgetKey = "moodboardlist";

const ColorGrid = (props) => {
  const diffRef = useRef();

  useEffect(() => {
    props.dispatch(
      getAllDbColors({
        key: getKey,
      })
    );
  }, []);

  const filterMoodboard = (color) => {
    props.dispatch(
      getMoodboardlist({
        payload: `page=1&diff=${diffRef.current.value}&color=${color.r}.${color.g}.${color.b}`,
        key: moodboardgetKey,
      })
    );
  };

  return (
    <>
      <input
        type="text"
        placeholder="Difference"
        className="m-2"
        style={{ height: "2rem" }}
        defaultValue={5}
        onChange={(event) => {}}
        ref={diffRef}
      />
      <Container fluid>
        <div className="d-flex flex-wrap">
          {props.colorsList?.data.length > 0 &&
            props.colorsList?.data.map((e, i) => {
              return (
                <div
                  key={`colordiv${i}`}
                  style={{
                    width: "1rem",
                    height: "1rem",
                    margin: "1px",
                    padding: "25px",
                    cursor: "pointer",
                    backgroundColor: `rgb(${e.r}, ${e.g}, ${e.b})`,
                  }}
                  onClick={() => {
                    filterMoodboard(e);
                  }}
                ></div>
              );
            })}
        </div>
      </Container>
    </>
  );
};
export default connect((state) => {
  return { colorsList: { ...state.GetData[getKey] } };
})(ColorGrid);
