import { connect } from "react-redux";
import { useEffect } from "react";
import { getBatchList } from "../../redux/AllThunk/SimilarImages";
import { Accordion } from "react-bootstrap";
import {Table} from "react-bootstrap";
import AccordianRow from "./AccordianRow";

const getKey = "similar_image_batches";
const resultKey = "similar_image_result";

const BatchList = (props)=>{ 

    useEffect(()=>{
        props.dispatch(
            getBatchList({
              key: getKey,
            })
        );
    },[])

    return (<>
        <h1>Batch List</h1>
        {/* <Table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Batch No.</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {props?.result?.data.length>0 && props.result.data.map((v,i)=>{
                    return (
                    <>
                    <tr key={i}>
                        <td>{i+1}</td>
                        <td>Batch- {v.batch_no}</td>
                        <td><button onClick={()=>{fetchResult(v.id)}}>Get Result</button></td>
                    </tr>
                    <tr>
                        {Object.keys(result).length>0 && (
                        <MatchResult result={result} imgPath={imgPath} />
                        )}
                    </tr>
                    </>
                    )
                })}
            </tbody>
        </Table> */}
        <Accordion defaultActiveKey="0" key={`accordion0`}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div className="col-1">#</div>
                    <div className="col-2">Batch No.</div>
                    <div className="col-2">Action</div>
                </Accordion.Header>
            </Accordion.Item>
        </Accordion>
        {props?.result?.data.length>0 && props.result.data.map((v,i)=>{

        return (
    
        <Accordion  key={`accordion${i}`}>
            <Accordion.Item eventKey={i}>
                <Accordion.Header>
                    <div className="col-1">{i+1}</div>
                    <div className="col-2">Batch- {v.batch_no}</div>
                    
                </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey={i}>
                <Accordion.Body>
                    <AccordianRow batch_no={v.batch_no} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        
        )
    })}
    </>)
}

export default connect((state)=>{
    return {
        result: {...state?.GetData?.[getKey] },
    };
})(BatchList)