// import ColorDiv from "../custom/Palettes/ColorDiv";
// import { connect } from "react-redux";
// import { useEffect, useState } from "react";
// import axios from "../redux/Interceptor";
// import { api_url, HTTP_OK } from "../Constants";
// import FetchFabricBtn from "../FinalPalettes/FetchFabricBtn";
// import FabricListDiv from "../FinalPalettes/FabricListDiv";

const Colors = (props) => {
  return (
    <div className="d-flex">
      {props.ele.map((e, i) => {
        const color = `rgba(${e.r},${e.g},${e.b},1)`;
        return (
          <div
            style={{
              display: "inline-block",
              width: "5rem",
              height: "5rem",
              background: color,
            }}
            key={`moodcolor` + i}
          ></div>
        );
      })}
    </div>
  );
};
export default Colors;
