import { createSlice } from "@reduxjs/toolkit";

export const MainSlice = createSlice({
  name: "mainslice",
  initialState: {
    activeHarmony: "Analogous",
    baseColor: "rgba(255, 0, 0, 1)",
    wheelPointers: ["rgba(255, 0, 0, 1)"],
    otherPointer: false,
    analogousDegree: 10,
    splitComplementDegree: 180,
    doubleSplitComplementDegree: 15,
  },
  reducers: {
    setBaseColor: (state, action) => {
      state.baseColor = action.payload;
    },
    setWheelPointers: (state, action) => {
      state.wheelPointers = action.payload;
    },
    setAnalogousDegree: (state, action) => {
      state.analogousDegree = action.payload;
    },
    setOtherPointerMoved: (state, action) => {
      state.otherPointer = action.payload;
    },
    setSplitComplementaryDegree: (state, action) => {
      state.splitComplementDegree = action.payload;
    },
    setDoubleSplitComplementDegree: (state, action) => {
      state.doubleSplitComplementDegree = action.payload;
    },
    setActiveHarmony: (state, action) => {
      state.activeHarmony = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBaseColor,
  setWheelPointers,
  setAnalogousDegree,
  setOtherPointerMoved,
  setSplitComplementaryDegree,
  setDoubleSplitComplementDegree,
  setActiveHarmony,
} = MainSlice.actions;

export default MainSlice.reducer;
