import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { getMoodboardlist } from "../redux/AllThunk/Moodboards";
import { web_url, moodboard_path } from "../Constants";
import CustomPaginate from "../common/CustomPaginate";
import ColorDiv from "../custom/Palettes/ColorDiv";
import MoodBoardDiv2 from "../FinalPalettes/MoodBoardDiv2";
import { Accordion } from "react-bootstrap";
import Colors from "./Colors";
import Fabrics from "./Fabrics";
import ColorGrid from "./ColorGrid";

const getKey = "moodboardlist";

const Moodboards = (props) => {
  const [active_page, setActive_page] = useState(1);
  const { dispatch } = props;

  const [list, setList] = useState([]);
  useEffect(() => {
    getList(active_page);
  }, []);

  const getList = (pagenumber) => {
    props.dispatch(
      getMoodboardlist({
        payload: `page=${pagenumber}`,
        key: getKey,
      })
    );
  };

  useEffect(() => {
    if (props.moodboardList.success) {
      setList(props.moodboardList.data.list.data);
    }
  }, [props.moodboardList]);

  const changePageHandler = (pagenumber) => {
    setActive_page(pagenumber);
    console.log(
      "------",
      props.moodboardList.data,
      //props.moodboardList.data.filter.length
    );

    props.moodboardList.data.filter !== undefined &&
    props.moodboardList.data.filter.length > 0
      ? props.dispatch(
          getMoodboardlist({
            payload: `page=${pagenumber}${props.moodboardList.data.filter}`,
            key: getKey,
          })
        )
      : getList(pagenumber);
  };

  return (
    <Container fluid>
      <Accordion defaultActiveKey="0" key={`gridacc`}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Color grid</Accordion.Header>
          <Accordion.Body>
            <ColorGrid />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      Mood boards List
      {list.length > 0 &&
        list.map((e, i) => {
          return (
            <Accordion defaultActiveKey="0" key={`accordion${i}`}>
              <Accordion.Item eventKey={i}>
                <Accordion.Header>
                  <img
                    src={
                      web_url + props.moodboardList?.data?.path + "/" + e.image
                    }
                    style={{ height: "5rem", width: "5rem" }}
                  />
                  <Colors ele={e.colors} />
                </Accordion.Header>
                <Accordion.Body>
                  <Fabrics ele={e.colors} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}
      {props.moodboardList?.data?.list?.data?.length > 0 && (
        <CustomPaginate
          per_page={props.moodboardList?.data?.list?.per_page}
          total_records={props.moodboardList?.data?.list?.total}
          click={changePageHandler}
          activePage={active_page}
        />
      )}
    </Container>
  );
};
export default connect((state, props) => {
  return {
    moodboardList: { ...state.GetData[getKey] },
  };
})(Moodboards);
