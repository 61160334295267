import ColorDiv from "../custom/Palettes/ColorDiv";
import {
  monochromatic,
  monochromatic_other_pointer,
} from "../custom/HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setWheelPointers } from "../redux/AllSlices/MainSlice";
import FetchFabricBtn from "./FetchFabricBtn";
import FabricListDiv from "./FabricListDiv";

const fabricKey = "monochromatic_fabric";

const Monochromatic = (props) => {
  const [colorArr, setColorArr] = useState([]);

  useEffect(() => {
    const colors = monochromatic(props.baseColor);
    setColorArr(colors);
    props.dispatch(setWheelPointers(colors));
  }, [props.baseColor]);

  //   useEffect(() => {
  //     if (props.otherPointer !== false) {
  //       const colors = monochromatic_other_pointer(props.otherPointer);
  //       setColorArr(colors);
  //       props.dispatch(setWheelPointers(colors));
  //     }
  //   }, [props.otherPointer]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <div className="d-flex">
      <div className="col-6">
        <div className="mb-2 text-center fw-bold">Monochromatic harmony</div>
        <div className="d-flex">{content}</div>
      </div>
      <div className="col-6">
        <div className="text-center">
          <FetchFabricBtn colors={colorArr} statekey={fabricKey} />
        </div>
        {props.fabricData.message.fabrics && (
          <FabricListDiv fabricList={props.fabricData.message.fabrics} />
        )}
      </div>
    </div>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    fabricData: { ...state.Forms[fabricKey] },
  };
})(Monochromatic);
