import Pagination from "react-js-pagination";

export default function CustomPaginate(props) {
  const from = props.activePage === 1 ? 1 : (props.activePage - 1) * 15 + 1;
  const to = from + 14 < props.total_records ? from + 14 : props.total_records;

  return (
    <div className="d-sm-flex align-items-center justify-content-between mt-4">
      <Pagination
        className="mb-2 ml-2 mr-2 page-item"
        activePage={props?.activePage}
        itemsCountPerPage={props?.per_page ? props.per_page : 0}
        totalItemsCount={props?.total_records ? props.total_records : 0}
        pageRangeDisplayed={5}
        onChange={props.click}
      />
      <div className="d-flex align-items-center page-dropdown">
        Showing {from} - {to} of {props.total_records}
      </div>
    </div>
  );
}
