import { Radio } from "@palmerhq/radio-group";
import { FRadioGroup } from "../common/RadioGroup";
export const MethodList = (props) => {
  const methods = [
    "Analogous",
    "Monochromatic",
    "Triad",
    "Complementary",
    "Split Complementary",
    "Double Split Complementary",
    "Square",
    "Compound",
    "Shades",
    "Custom",
  ];

  return (
    <FRadioGroup name="colorharmony" autoFocus={false}>
      {methods.map((each, index) => {
        return (
          <Radio key={`option${index}`} value={each}>
            {each}
          </Radio>
        );
      })}
    </FRadioGroup>
  );
};
