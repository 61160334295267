import { Link } from "react-router-dom";
import {web_url} from "./Constants";

import {
  customPaletteGenerator,
  tinycolor,
  generatePalettes,
  moodboards,
  similarimages,
  singleupload,
  batchList,
  cavmatch,
  moodMatch
} from "./route";

const Home = () => {
  return (
    <>
      <div className="p-2">
        <a href={web_url+'fabric'} target="_blank">
          Fabrics With color palette
        </a>
      </div>
      <div className="p-2">
        <a href={web_url+"moodboard"} target="_blank">
          Moodboards With color palette
        </a>
      </div>
      <div className="p-2">
        <Link to={customPaletteGenerator}>Custom Palette</Link>
      </div>
      <div className="p-2">
        <Link to={tinycolor}>Tiny color</Link>
      </div>
      <div className="p-2">
        <Link to={moodboards}>Mood Boards</Link>
      </div>
      <div className="p-2">
        <Link to={generatePalettes}> Select color and generate palettes</Link>
      </div>
      <div className="p-2">
        <Link to={batchList}> Upload Batch List</Link>
      </div>
      <div className="p-2">
        <Link to={similarimages}> Similar Images</Link>
      </div>
      <div className="p-2">
        <Link to={moodMatch}> Mood Match</Link>
      </div>
      <div className="p-2">
        <Link to={singleupload}> Single Upload</Link>
      </div>

      <div className="p-2">
        <Link to={cavmatch}> Upload & Get Similar images</Link>
      </div>
    </>
  );
};
export default Home;
