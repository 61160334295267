import { parseColor } from "@react-stately/color";
import store from "../redux/store";

function harmonize(color, start, format) {
  const [h, s, l] = parseHSL(returnFormat(color, "hsl"));
  const h1 = (h + start) % 360;
  const hslstring = `hsl(${h1}, ${s}%, ${l}%)`;
  return returnFormat(hslstring, format);
}

export const returnFormat = (color, format) => {
  switch (format) {
    case "rgba": {
      const parsedcolor = parseColor(color);
      return parsedcolor.toString("rgba");
    }
    case "hsl": {
      const parsedcolor = parseColor(color);
      return parsedcolor.toString("hsl");
    }
    case "hsva": {
      const parsedcolor = parseColor(color);
      return parsedcolor.toString("hsva");
    }
  }
};

function parseHSL(str) {
  var hsl, h, s, l;
  hsl = str.split(",");
  h = parseFloat(Number(hsl[0].replace(/[hsl(]/g, "")).toFixed(2));
  s = parseFloat(Number(hsl[1].replace(/[%]/g, "")).toFixed(2));
  l = parseFloat(Number(hsl[2].replace(/[%)]/g, "")).toFixed(2));

  return [h, s, l]; // return parts
}

function parseHSV(str) {
  var hsv, h, s, v;
  hsv = str.split(",");
  h = parseFloat(Number(hsv[0].replace(/[hsv(]/g, "")).toFixed(2));
  s = parseFloat(Number(hsv[1].replace(/[%]/g, "")).toFixed(2));
  v = parseFloat(Number(hsv[2].replace(/[%)]/g, "")).toFixed(2));

  return [h, s, v]; // return parts
}

function parseRGBA(str) {
  var rgba, r, g, b, a;
  rgba = str.split(",");
  r = Number(rgba[0].replace(/[rgba(]/g, "")); // convert to number
  g = Number(rgba[1]);
  b = Number(rgba[2]);
  a = Number(rgba[3].replace(/[)]/g, ""));
  return [r, g, b, a]; // return parts
}

function parseHSL3(color) {
  const str = returnFormat(color, "hsl");
  var hsl, h, s, l;
  hsl = str.replace(/[^\d,]/g, "").split(","); // strip non digits ('%')
  h = Number(hsl[0]); // convert to number
  s = Number(hsl[1]);
  l = Number(hsl[2]);
  return [h, s, l]; // return parts
}

function harmonize3(color, start, end, interval) {
  const colors = [color];
  const [h, s, l] = parseHSL3(color);

  for (let i = start; i <= end; i += interval) {
    const h1 = (h + i) % 360;
    const c1 = `hsl(${h1}, ${s}%, ${l}%)`;
    colors.push(c1);
  }

  return colors;
}

export const split = (color_hsl) => {
  return harmonize3(color_hsl, 150, 210, 60);
};

export const tetrad = (color_hsl) => {
  return harmonize3(color_hsl, 90, 270, 90);
};

//=====================

export const complement = (color, format = "rgba") => {
  const diff = 180;
  const arr = [];
  arr[0] = color;
  // arr[1] = harmonize(color, diff, format);
  // arr[2] = harmonize(color, diff + 5, format);
  // arr[3] = harmonize(color, diff + 10, format);
  // arr[4] = harmonize(color, diff + 15, format);

  arr[1] = change_saturation(color, -20, format);
  arr[2] = harmonize(color, diff, format);
  arr[3] = change_saturation(arr[2], -20, format);
  arr[4] = change_saturation(arr[2], -30, format);
  return arr;
};

export const complement_other_pointer = (colorObj, format = "rgba") => {
  const diff = 180;
  const arr = [];
  arr[colorObj.index] = colorObj.color;
  if (colorObj.index == 1) {
    arr[1] = harmonize(colorObj.color, diff, format);
    arr[2] = harmonize(colorObj.color, diff + 5, format);
    arr[3] = harmonize(colorObj.color, diff + 10, format);
    arr[4] = harmonize(colorObj.color, diff + 15, format);
  }

  return arr;
};

//================Analogous==============
export const get_analogous_pointer_degree = (colorObj) => {
  const storestate = store.getState();
  const prev_pointers = storestate.mainslice.wheelPointers;

  const color = colorObj.color;
  const colorindex = colorObj.pointer_index;
  let h_diff = storestate.mainslice.analogousDegree;

  const [h1, s1, l1] = parseHSL(returnFormat(prev_pointers[0], "hsl"));
  const [h] = parseHSL(returnFormat(color, "hsl"));
  let baseHue = h1;

  if (colorindex == 1 || colorindex == 3) {
    if (h - baseHue > 180) {
      const diff = h - baseHue;
      h_diff = (360 - diff) % 360;
    } else {
      h_diff = (h - baseHue) % 360;
    }
  } else if (colorindex == 2 || colorindex == 4) {
    if (h - baseHue > 180) {
      const diff = h - baseHue;
      h_diff = ((360 - diff) / 2) % 360;
    } else {
      h_diff = ((h - baseHue) / 2) % 360;
    }
  }
  return Math.abs(h_diff);
};

export const analogous = (color, format = "rgba") => {
  const storestate = store.getState();

  const degree = storestate.mainslice.analogousDegree;
  const arr = [];
  arr[0] = color;
  arr[1] = harmonize(color, degree, format);
  arr[2] = harmonize(color, degree * 2, format);
  arr[3] = harmonize(color, degree * -1, format);
  arr[4] = harmonize(color, degree * -2, format);
  return arr;
};

export const analogous_other_pointer = (colorObj, format = "rgba") => {
  const storestate = store.getState();
  const angle_diff = storestate.mainslice.analogousDegree;
  const prev_pointers = storestate.mainslice.wheelPointers;
  let arr = [...prev_pointers];
  const [base, s, l] = parseHSL(returnFormat(prev_pointers[0], "hsl"));

  const color = colorObj.color;
  const colorindex = colorObj.pointer_index;
  arr[colorindex] = color;
  let newHue;

  for (let i = 1; i <= 4; i++) {
    if (i == 1) {
      newHue = base + angle_diff;
    } else if (i == 2) {
      newHue = base + angle_diff * 2;
    } else if (i == 3) {
      newHue = base - angle_diff;
    } else if (i == 4) {
      newHue = base - angle_diff * 2;
    }
    const hslstring = `hsl(${newHue}, ${s}%, ${l}%)`;
    arr[i] = returnFormat(hslstring, format);
  }
  return arr;
};

//===================Analogous End========

//===================triad start===================

export const triad = (color, format = "rgba") => {
  const diff = 120;
  const arr = [];
  arr[0] = color;
  arr[1] = harmonize(color, diff, format);
  arr[2] = change_saturation(arr[1], -20, format);
  arr[3] = harmonize(color, -diff - 30, format);
  arr[4] = change_saturation(arr[3], -20, format);
  return arr;
};

export const triad_other_pointer = (colorObj, format = "rgba") => {
  const diff = 120;
  const storestate = store.getState();

  const prev_pointers = storestate.mainslice.wheelPointers;
  const prevColor = prev_pointers[colorObj.pointer_index];
  // const [h, s, l] = parseHSL(returnFormat(prevColor, "hsl"));
  // const [h1, s1, l1] = parseHSL(returnFormat(colorObj.color, "hsl"));

  let arr = [...prev_pointers];
  //let arr = [];
  arr[colorObj.pointer_index] = colorObj.color;

  switch (colorObj.pointer_index) {
    case 1: {
      arr[0] = harmonize(arr[1], diff, format);
      arr[2] = change_saturation(arr[1], -20, format);
      arr[3] = harmonize(arr[1], -diff - 30, format);
      arr[4] = change_saturation(arr[3], -20, format);
    }
    case 2: {
      arr[0] = harmonize(arr[1], diff, format);
      arr[1] = change_saturation(arr[2], 20, format);
      arr[3] = harmonize(arr[2], -diff + 30, format);
      arr[4] = change_saturation(arr[3], -20, format);
    }
    case 3: {
      arr[0] = harmonize(arr[3], diff + 30, format);
      arr[1] = harmonize(arr[3], -diff + 30, format);
      arr[2] = change_saturation(arr[1], -20, format);
      arr[4] = change_saturation(arr[3], -20, format);
    }
    case 4: {
      arr[3] = change_saturation(arr[4], 20, format);
      arr[1] = harmonize(arr[3], -diff + 30, format);
      arr[2] = change_saturation(arr[1], -20, format);
      arr[0] = harmonize(arr[3], diff + 30, format);
    }
  }

  const color = colorObj.color;
  // const [base, s, l] = parseHSL(returnFormat(color, "hsl"));

  // let newHue;

  // for (let i = 1; i <= 4; i++) {
  //   if (i == 1) {
  //     newHue = base - 120;
  //   } else if (i == 2) {
  //     newHue = base - 130;
  //   } else if (i == 3) {
  //     newHue = base - 250;
  //   } else if (i == 4) {
  //     newHue = base - 24;
  //   }
  //   const hslstring = `hsl(${newHue}, ${s}%, ${l}%)`;
  //   arr[0] = returnFormat(hslstring, format);
  // }

  // arr[1] = harmonize(color, diff, format);
  // arr[2] = harmonize(color, 130, format);
  // arr[3] = harmonize(color, -diff, format);
  //arr[4] = harmonize(color, -diff, format);
  return arr;
};
//===================triad end===================

//===================Monochromatic===================

function change_saturation(color, start, format) {
  const [h, s, l] = parseHSL(returnFormat(color, "hsl"));
  const s1 = s + start;
  const hslstring = `hsl(${h}, ${s1}%, ${l}%)`;
  return returnFormat(hslstring, format);
}

export const monochromatic = (color, diff = "15", format = "rgba") => {
  const arr = [];
  const [h, s, l] = parseHSL(returnFormat(color, "hsl"));
  arr[0] = color;
  arr[1] = change_saturation(color, diff * -2, format);
  arr[2] = change_saturation(color, diff * -3, format);
  arr[3] = change_saturation(color, diff * -4, format);
  arr[4] = change_saturation(color, diff * -5, format);
  return arr;
};

// function change_saturation_2(color, newsaturation, format) {
//   const [h, s, l] = parseHSL(returnFormat(color, "hsl"));
//   const hslstring = `hsl(${h}, ${newsaturation}%, ${l}%)`;
//   return returnFormat(hslstring, format);
// }

export const monochromatic_other_pointer = (
  colorObj,
  diff = "15",
  format = "rgba"
) => {
  const arr = [];
  arr[colorObj.pointer_index] = colorObj.color;

  const [h, s, l] = parseHSL(returnFormat(colorObj.color, "hsl"));

  const color = `hsl(${h}, 100%, ${l}%)`;
  arr[0] = color;

  switch (colorObj.pointer_index) {
    case 1: {
      arr[2] = change_saturation(color, diff * -3, format);
      arr[3] = change_saturation(color, diff * -4, format);
      arr[4] = change_saturation(color, diff * -5, format);
    }
    case 2: {
      arr[1] = change_saturation(color, diff * -2, format);
      arr[3] = change_saturation(color, diff * -4, format);
      arr[4] = change_saturation(color, diff * -5, format);
    }
    case 3: {
      arr[1] = change_saturation(color, diff * -2, format);
      arr[2] = change_saturation(color, diff * -3, format);
      arr[4] = change_saturation(color, diff * -5, format);
    }
    case 4: {
      arr[1] = change_saturation(color, diff * -2, format);
      arr[2] = change_saturation(color, diff * -3, format);
      arr[3] = change_saturation(color, diff * -4, format);
    }
  }
  return arr;
};

//===================Monochromatic end===================

//===================split complement start===================

export const split_complement = (color, format = "rgba") => {
  const diff = 180;
  const arr = [];
  arr[0] = color;
  arr[1] = harmonize(color, diff - 15, format);
  arr[2] = harmonize(color, diff - 25, format);
  arr[3] = harmonize(color, diff + 15, format);
  arr[4] = harmonize(color, diff + 25, format);
  return arr;
};

export const get_splitcomplementary_pointer_degree = (colorObj) => {
  const storestate = store.getState();
  const prev_pointers = storestate.mainslice.wheelPointers;

  const color = colorObj.color;
  const colorindex = colorObj.pointer_index;
  let h_diff = storestate.mainslice.splitComplementDegree;

  const [h1, s1, l1] = parseHSL(returnFormat(prev_pointers[0], "hsl"));
  const [h] = parseHSL(returnFormat(color, "hsl"));
  let baseHue = h1;

  if (h - baseHue > 180) {
    const diff = h - baseHue;
    h_diff = (360 - diff) % 360;
  } else {
    h_diff = (h - baseHue) % 360;
  }

  return Math.abs(h_diff);
};

export const split_complement_otherpointer = (colorObj, format = "rgba") => {
  const storestate = store.getState();
  const angle_diff = storestate.mainslice.splitComplementDegree;
  const prev_pointers = storestate.mainslice.wheelPointers;
  let arr = [...prev_pointers];
  const [base, s, l] = parseHSL(returnFormat(prev_pointers[0], "hsl"));

  const color = colorObj.color;
  const colorindex = colorObj.pointer_index;
  arr[colorindex] = color;
  let newHue;

  switch (colorindex) {
    case 1:
      for (let i = 1; i <= 4; i++) {
        if (i == 1) {
          newHue = angle_diff;
          console.log("newHue--1", newHue);
        } else if (i == 2) {
          newHue = angle_diff - 10;
          console.log("newHue--2", newHue);
        } else if (i == 3) {
          newHue = -angle_diff;
          console.log("newHue--3", newHue);
        } else if (i == 4) {
          newHue = -angle_diff + 10;
          console.log("newHue--4", newHue);
        }

        if (angle_diff > 180) {
          newHue = 360 - newHue;
        }
        const hslstring = `hsl(${newHue}, ${s}%, ${l}%)`;
        arr[i] = returnFormat(hslstring, format);
      }
      break;
    case 2:
      for (let i = 1; i <= 4; i++) {
        if (i == 1) {
          newHue = angle_diff + 10;
        } else if (i == 2) {
          newHue = angle_diff;
          console.log("newHue--2", newHue);
        } else if (i == 3) {
          newHue = -angle_diff - 10;
        } else if (i == 4) {
          newHue = -angle_diff;
          console.log("newHue--4", newHue);
        }
        const hslstring = `hsl(${newHue}, ${s}%, ${l}%)`;
        arr[i] = returnFormat(hslstring, format);
      }
      break;
    case 3:
      for (let i = 1; i <= 4; i++) {
        if (i == 1) {
          newHue = angle_diff;
          console.log("newHue--1", newHue);
        } else if (i == 2) {
          newHue = angle_diff - 10;
          console.log("newHue--2", newHue);
        } else if (i == 3) {
          newHue = -angle_diff;
          console.log("newHue--3", newHue);
        } else if (i == 4) {
          newHue = -angle_diff + 10;
          console.log("newHue--4", newHue);
        }
        const hslstring = `hsl(${newHue}, ${s}%, ${l}%)`;
        arr[i] = returnFormat(hslstring, format);
      }

      break;
    case 4:
      for (let i = 1; i <= 4; i++) {
        if (i == 1) {
          newHue = angle_diff - 10;
          console.log("newHue--1", newHue);
        } else if (i == 2) {
          newHue = angle_diff;
          console.log("newHue--2", newHue);
        } else if (i == 3) {
          newHue = -angle_diff + 10;
          console.log("newHue--3", newHue);
        } else if (i == 4) {
          newHue = -angle_diff;
          console.log("newHue--4", newHue);
        }
        // if (angle_diff > 180) {
        //   newHue = 360 - newHue;
        // }
        const hslstring = `hsl(${newHue}, ${s}%, ${l}%)`;
        arr[i] = returnFormat(hslstring, format);
      }

      break;
  }

  return arr;
};
//===================split complement end===================

//============double split complementary start===========

export const double_split_complement = (color, format = "rgba") => {
  const diff = 15;
  const arr = [];
  arr[0] = color;
  arr[1] = harmonize(color, diff, format);
  arr[2] = harmonize(color, -diff, format);
  arr[3] = harmonize(color, 180 - diff, format);
  arr[4] = harmonize(color, 180 + diff, format);

  return arr;
};

export const get_doubleSplitcomplementary_pointer_degree = (colorObj) => {
  const storestate = store.getState();
  const prev_pointers = storestate.mainslice.wheelPointers;

  const color = colorObj.color;
  const colorindex = colorObj.pointer_index;
  let h_diff = storestate.mainslice.doubleSplitComplementDegree;

  const [h1, s1, l1] = parseHSL(returnFormat(prev_pointers[0], "hsl"));
  const [h] = parseHSL(returnFormat(color, "hsl"));
  let baseHue = h1;

  if (h - baseHue > 180) {
    const diff = h - baseHue;
    h_diff = (360 - diff) % 360;
  } else {
    h_diff = (h - baseHue) % 360;
  }
  return Math.abs(h_diff);
};

export const double_split_complement_otherpointer = (
  colorObj,
  format = "rgba"
) => {
  const storestate = store.getState();
  const angle_diff = storestate.mainslice.doubleSplitComplementDegree;
  const prev_pointers = storestate.mainslice.wheelPointers;
  let arr = [...prev_pointers];
  const [base, s, l] = parseHSL(returnFormat(prev_pointers[0], "hsl"));

  const color = colorObj.color;
  const colorindex = colorObj.pointer_index;
  arr[colorindex] = color;
  let newHue;
  console.log({ base, angle_diff });

  switch (colorindex) {
    case 1:
    case 2:
      for (let i = 1; i <= 4; i++) {
        if (i == 1) {
          newHue = base + angle_diff;
          console.log("newHue--1", newHue);
        } else if (i == 2) {
          newHue = base - angle_diff;
          console.log("newHue--2", newHue);
        } else if (i == 3) {
          newHue = base + 180 - angle_diff;
          console.log("newHue--3", newHue);
        } else if (i == 4) {
          newHue = base + 180 + angle_diff;
          console.log("newHue--4", newHue);
        }
        const hslstring = `hsl(${newHue}, ${s}%, ${l}%)`;
        arr[i] = returnFormat(hslstring, format);
      }
      break;

    case 3:
    case 4:
      for (let i = 1; i <= 4; i++) {
        if (i == 1) {
          newHue = base + angle_diff - 180;
          console.log("newHue--1", newHue);
        } else if (i == 2) {
          newHue = base - angle_diff - 180;
          console.log("newHue--2", newHue);
        } else if (i == 3) {
          newHue = base + angle_diff;
          console.log("newHue--3", newHue);
        } else if (i == 4) {
          newHue = base - angle_diff;
          console.log("newHue--4", newHue);
        }
        const hslstring = `hsl(${newHue}, ${s}%, ${l}%)`;
        arr[i] = returnFormat(hslstring, format);
      }
      break;
  }

  return arr;
};

//============double split complementary end===========

export const square = (color, format = "rgba") => {
  const arr = [];
  arr[0] = color;
  arr[1] = harmonize(color, 180, format);
  arr[2] = harmonize(color, 90, format);
  arr[3] = harmonize(color, 270, format);
  //arr[4] = harmonize(color, 275, format);
  return arr;
};

export const compound = (color, format = "rgba") => {
  const diff = 30;
  const base = 0;

  const arr = [];
  arr[0] = color;
  arr[1] = harmonize(color, base + diff, format);
  arr[2] = change_saturation(arr[1], -40, format);

  const [h, s, l] = parseHSL(returnFormat(color, "hsl"));

  arr[3] = `hsl(${h + base + 180 - 30}, ${s}%, ${l - 10}%)`;
  arr[4] = `hsl(${h + base + 180 - 20}, ${l < 50 ? s - 20 : s}%, ${l + 10}%)`;

  arr[3] = returnFormat(arr[3], "rgba");
  arr[4] = returnFormat(arr[4], "rgba");
  return arr;
};

export const dark_shades = (color, format = "rgba") => {
  const arr = [];
  arr[0] = color;
  const rgba = parseRGBA(color);

  arr[1] = `rgba(${(6 * rgba[0]) / 10},${(6 * rgba[1]) / 10},${
    (6 * rgba[2]) / 10
  },${rgba[3]})`;
  arr[2] = `rgba(${(5 * rgba[0]) / 10},${(5 * rgba[1]) / 10},${
    (5 * rgba[2]) / 10
  },${rgba[3]})`;
  arr[3] = `rgba(${(4 * rgba[0]) / 10},${(4 * rgba[1]) / 10},${
    (4 * rgba[2]) / 10
  },${rgba[3]})`;
  arr[4] = `rgba(${(3 * rgba[0]) / 10},${(3 * rgba[1]) / 10},${
    (3 * rgba[2]) / 10
  },${rgba[3]})`;
  return arr;
};

export const light_shades = (color, format = "rgba") => {
  const arr = [];
  arr[0] = color;
  const rgba = parseRGBA(color);

  arr[1] = `rgba(${(14 * rgba[0]) / 10},${(14 * rgba[1]) / 10},${
    (14 * rgba[2]) / 10
  },${rgba[3]})`;
  arr[2] = `rgba(${(15 * rgba[0]) / 10},${(15 * rgba[1]) / 10},${
    (15 * rgba[2]) / 10
  },${rgba[3]})`;
  arr[3] = `rgba(${(16 * rgba[0]) / 10},${(16 * rgba[1]) / 10},${
    (16 * rgba[2]) / 10
  },${rgba[3]})`;
  arr[4] = `rgba(${(17 * rgba[0]) / 10},${(17 * rgba[1]) / 10},${
    (17 * rgba[2]) / 10
  },${rgba[3]})`;
  return arr;
};

export const custom_palette = (colorObj, format = "rgba") => {
  // arr[0] = color;

  const storestate = store.getState();
  const prev_pointers = storestate.mainslice.wheelPointers;
  let arr = [...prev_pointers];
  const [base, s, l] = parseHSL(returnFormat(prev_pointers[0], "hsl"));

  const color = colorObj.color;
  const colorindex = colorObj.pointer_index;
  arr[colorindex] = color;

  return arr;
};
