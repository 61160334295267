import ColorDiv from "./ColorDiv";
import {
  analogous,
  get_analogous_pointer_degree,
  analogous_other_pointer,
} from "../HarmonyFuctions";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import {
  setWheelPointers,
  setAnalogousDegree,
} from "../../redux/AllSlices/MainSlice";

const Analogous = (props) => {
  const [colorArr, setColorArr] = useState([]);
  useEffect(() => {
    const colors = analogous(props.baseColor);
    setColorArr(colors);
    props.dispatch(setWheelPointers(colors));
  }, [props.baseColor]);

  useEffect(() => {
    if (props.otherPointer != false) {
      const new_degree = get_analogous_pointer_degree(props.otherPointer);
      props.dispatch(setAnalogousDegree(new_degree));
    }
  }, [props.otherPointer]);

  useEffect(() => {
    if (props.otherPointer != false) {
      const colors = analogous_other_pointer(props.otherPointer);
      setColorArr(colors);
      props.dispatch(setWheelPointers(colors));
    }
  }, [props.analogousDegree]);

  let content = colorArr.map((e, i) => {
    return <ColorDiv bgcolor={e} key={`color` + i} sequence={i} />;
  });

  return (
    <>
      <Row>Analogous harmony (3)</Row>
      <div className="d-flex">{content}</div>
    </>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    wheelpointers: state.mainslice.wheelPointers,
    otherPointer: state.mainslice.otherPointer,
    analogousDegree: state.mainslice.analogousDegree,
  };
})(Analogous);
