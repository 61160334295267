import axios from "../Interceptor";
import { api_url, HTTP_OK } from "../../Constants";

export const getDbColors = (action) => {
  return (dispatch) => {
    axios({
      url: api_url + "getcolors",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );
  };
};

export const getAllDbColors = (action) => {
  return (dispatch) => {
    axios({
      url: api_url + "get_allfabric_colors",
      method: "get",
      //data: action.payload,
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );
  };
};

export const getMatchingFabric = (action) => {
  return (dispatch) => {
    axios({
      url: api_url + "getColorBasedFabrics",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );
  };
};
