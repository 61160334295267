import { connect } from "react-redux";
import { useEffect,useState } from "react"; 
import { getSimilarImageResult } from "../../redux/AllThunk/SimilarImages";
import MatchResult from "../Similarimages/MatchResult";
import {multipart_instance as axios} from "../../redux/Interceptor";
import { api_url, HTTP_OK } from "../../Constants";

const resultKey = "similar_image_result";

const AccordianRow = (props)=>{

    const[result,setResult] = useState({});
    const[imgPath,setImgPath] = useState("");
    // const fetchResult = (batchnumber)=>{

    //     // const batchnumber = "batch_6";
     
    //      props.dispatch(
    //        getSimilarImageResult({
    //          payload:{batchnumber},
    //          key: resultKey,
    //        })
    //      );
    // }


    const fetchResult = (batchnumber)=>{
        axios({
            url: api_url + "getSimilarImageResult",
            method: "post",
            data: {batchnumber},
          }).then(
            (response) => {
              if (response.status == HTTP_OK) {
                setResult(response?.data.data);
                setImgPath(response?.data.similar_img_path);
                // dispatch({
                //   type: "GET_SUCCESS",
                //   //payload: response?.data.data,
                //   payload: {data:response?.data.data,imgUrl:response?.data.similar_img_path},
                //   key: action.key,
                // });
              } 
            }
          );
    }

    // useEffect(()=>{
    //     props?.imagesResult?.data.data !==undefined && setResult(props?.imagesResult?.data.data);
    //     props?.imagesResult?.data.imgUrl!==undefined && setImgPath(props?.imagesResult?.data.imgUrl);
    // },[props?.imagesResult?.data]);

    console.log('result',result,imgPath);

    return (<><div className="col-2">
    <button onClick={()=>{fetchResult(props.batch_no)}}>Get Result</button>
    </div>
    {Object.keys(result).length>0 ? (
    <MatchResult result={result} imgPath={imgPath} />
    ):("No result Found")} 
    </>)
}
export default AccordianRow;
// export default connect((state)=>{
//     return {
//         imagesResult: {...state?.GetData?.[resultKey] }
//     };
// })(AccordianRow)