import ColorDiv from "../custom/Palettes/ColorDiv";
import FetchFabricBtn from "./FetchFabricBtn";
import FabricListDiv from "./FabricListDiv";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import axios from "../redux/Interceptor";
import { api_url, HTTP_OK } from "../Constants";

const fabricKey = "moodBoard";
const MoodBoardDiv2 = (props) => {
  const [colorArr, setColorArr] = useState([]);
  const [fabricData, setFabricData] = useState("");

  useEffect(() => {
    let rgbaArr = props.ele.map((e, i) => {
      return `rgba(${e.r},${e.g},${e.b},1)`;
    });
    setColorArr(rgbaArr);
  }, []);

  let colors = props.ele.map((e, i) => {
    const color = `rgba(${e.r},${e.g},${e.b},1)`;
    return <ColorDiv bgcolor={color} key={`moodcolor` + i} sequence={i} />;
  });

  const get_machingFabric = () => {
    axios({
      url: api_url + "getColorBasedFabrics",
      method: "post",
      data: {
        colors: colorArr,
      },
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          setFabricData(response?.data?.data.fabrics);
          //   dispatch({
          //     type: "FORM_SUCCESS",
          //     payload: response?.data?.data,
          //     key: action.key,
          //   });
        } else {
          //   dispatch({
          //     type: "FORM_ERROR",
          //     payload: response?.response?.data,
          //     key: action.key,
          //   });
        }
      },
      (error) => {
        // dispatch({
        //   type: "FORM_ERROR",
        //   payload: error?.message,
        //   key: action.key,
        // });
      }
    );
  };

  return (
    <div className="d-flex">
      <div className="col-6" style={{ overflowX: "scroll" }}>
        <div className="mb-2 text-center fw-bold">
          Mood Board Colors
          {props.i}
        </div>
        <div className="d-flex">{colors}</div>
      </div>
      <div className="col-6" style={{ overflowX: "scroll" }}>
        <div className="text-center">
          <button
            onClick={() => {
              get_machingFabric();
            }}
            className="m-2"
          >
            Get Fabrics
          </button>
        </div>
        {fabricData.length > 0 && (
          <>
            <FabricListDiv fabricList={fabricData} />
          </>
        )}
      </div>
    </div>
  );
};
export default connect()(MoodBoardDiv2);
