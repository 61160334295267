import iro from "@jaames/iro";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useRef } from "react";
const IroWheel = (props) => {
  const [colorPicker, setcp] = useState(null);
  const inputRef = useRef();

  const wheelElementRef = useRef();

  useEffect(() => {
    if (!!wheelElementRef.current) {
      wheelElementRef.current.innerHTML = "";

      const wheel = new iro.ColorPicker(wheelElementRef.current, {
        width: 260,
        colors: [props.baseColor],
        //colors: ["rgba(255, 0, 0,1)"],

        handleRadius: 9,
        layout: [
          {
            component: iro.ui.Wheel,
          },
          {
            component: iro.ui.Slider,
            options: {
              sliderType: "saturation",
            },
          },
          {
            component: iro.ui.Slider,
            options: {
              sliderType: "value",
            },
          },
          {
            component: iro.ui.Slider,
            options: {
              sliderType: "red",
            },
          },
          {
            component: iro.ui.Slider,
            options: {
              sliderType: "green",
            },
          },
          {
            component: iro.ui.Slider,
            options: {
              sliderType: "blue",
            },
          },
          {
            component: iro.ui.Slider,
            options: {
              sliderType: "alpha",
            },
          },

          {
            component: iro.ui.Slider,
            options: {
              sliderType: "hue",
            },
          },

          {
            component: iro.ui.Slider,
            options: {
              sliderType: "kelvin",
            },
          },
        ],
      });
      wheel.on(["color:change"], function (color) {
        if (color.index === 0) {
          props.BaseColorChangeHander(color.rgbaString);
        }
      });
      setcp(wheel);
    }
  }, [wheelElementRef.current]);

  useEffect(() => {
    if (!!colorPicker) {
      colorPicker.setColors([props.baseColor]);
    }
  }, [props.baseColor]);

  return (
    <div className="d-flex">
      <div className="col-6">
        <div className="mb-3 col-4 text-center">
          <Field name="colorInput">
            {({ field }) => (
              <>
                <Form.Control
                  {...field}
                  type="text"
                  autoComplete="off"
                  value={field.value ? field.value : ""}
                />
              </>
            )}
          </Field>
        </div>

        <div id="colorPicker" ref={wheelElementRef}></div>
      </div>
      <div className="col-6">
        <div>My color</div>
        <div
          style={{
            width: "5rem",
            height: "5rem",
            backgroundColor: props.baseColor,
          }}
        ></div>
        <button
          onClick={() => {
            props.getFromdb(props.baseColor);
          }}
        >
          Click to get moodboards
        </button>
      </div>
    </div>
  );
};
export default connect((state) => {
  return {
    baseColor: state.mainslice.baseColor,
    wheelpointers: state.mainslice.wheelPointers,
  };
})(IroWheel);
