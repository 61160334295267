import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import {
  customPaletteGenerator,
  tinycolor,
  //colorExtractor,
  generatePalettes,
  moodboards,
  colorgrid,
  similarimages,
  singleupload,
  batchList,
  cavmatch,
  moodMatch
} from "./route";

import Tinycolor from "./Tinycolor";
import CustomPalettes from "./custom/index";
import Home from "./Home";
import ColorExtractor from "./ColorExtractor";
import FinalPalettes from "./FinalPalettes";
import Moodboards from "./components/moodboards";
import ColorGrid from "./components/ColorGrid";
import BatchList from "./components/BatchList";
import SimilarImages from "./components/Similarimages";
import SingleUpload from "./components/Similarimages/SingleUpload";
import CavMatch from "./components/Similarimages/CavMatch";
import MoodMatch from "./components/MoodMatch";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={customPaletteGenerator} element={<CustomPalettes />} />
          <Route path={tinycolor} element={<Tinycolor />} />
          <Route path="/" element={<Home />} />
          {/* <Route path={colorExtractor} element={<ColorExtractor />} /> */}
          <Route path={generatePalettes} element={<FinalPalettes />} />
          <Route path={moodboards} element={<Moodboards />} />
          <Route path={colorgrid} element={<ColorGrid />} />
          <Route path={similarimages} element={<SimilarImages />} />
          <Route path={moodMatch} element={<MoodMatch />} />
          <Route path={singleupload} element={<SingleUpload />} />
          <Route path={batchList} element={<BatchList />} />
          <Route path={cavmatch} element={<CavMatch />} />
        </Routes>
      </BrowserRouter>
    </>
  );  
}

export default App;
